import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { Box, Paper, Typography, Button, useMediaQuery } from "@mui/material";
import sh from "bundles/common/utils/sh";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Divider from "@mui/material/Divider";


const SavedJobs = ({ candidateId }) => {
  const [savedJobs, setSavedJobs] = useState([]);
  const navigate = useNavigate();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (candidateId) {
      sh.get(`/candidates/${candidateId}/saved_jobs`)
        .then((res) => {
          setSavedJobs(res.data);
        })
        .catch((error) => {
          console.error("Error fetching saved jobs:", error);
        });
    }
  }, [candidateId]);

  const handleApplyRedirect = (jobId) => {
    navigate(`/${i18nLocale}/candidate/job_offers?jid=${jobId}`);
  };

  const goToFavorites = () => {
    navigate(`/${i18nLocale}/candidate/job_offers?onlyFavorites=true`);
  };

  return (
    <Paper elevation={3} style={{ padding: "16px", borderRadius: "15px" }}>
      <Box mb={2} style={{ display: isDesktop ? "unset" : "flex", justifyContent: isDesktop ? "unset" : "center" }}>
        <Typography variant="h5" gutterBottom
          style={{ fontWeight: "bold", fontSize: isDesktop ? "18px" : "24px" }}
        >
          <FormattedMessage
            id="candidate.my_saved_jobs"
            values={{ count: savedJobs.length }}
          />
        </Typography>
      </Box>
      {savedJobs.length > 0 ? (
        savedJobs.slice(0, 2).map((job) => (
          <Box key={job.id} mb={2}>
            <Typography variant="body1" style={{ fontSize: isDesktop ? "16px" : "18px" }}>
              <b>{job.job_title}</b>
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              fontWeight="bold"
              style={{ fontSize: isDesktop ? "14px" : "16px" }}
            >
              {job.company?.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: isDesktop ? "14px" : "16px" }}>
              {job.locations[0]?.city.charAt(0).toUpperCase() + job.locations[0]?.city.slice(1).toLowerCase()}
              ,{" "}
              <FormattedMessage
                id={`regions.${job.locations[0]?.region.toLowerCase()}`}
                defaultMessage={job.locations[0]?.region}
              />
            </Typography>
            <Button
              variant="rounded"
              size="xsmall"
              color="secondaryOutlined"
              onClick={() => handleApplyRedirect(job.id)}
              style={{ marginTop: "8px" }}
            >
              <FormattedMessage id="job_search.apply" />
            </Button>
            <Divider style={{ marginTop: "8px" }} />
          </Box>
        ))
      ) : (
        <Typography variant="body2">
          <FormattedMessage id="candidate.no_saved_jobs" />
        </Typography>
      )}
      <Box
        mt={2}
        display="flex"
        alignItems="left"
        style={{ cursor: "pointer", fontWeight: "bolder" }}
        onClick={goToFavorites}
      >
        <Typography
          style={{ fontWeight: "bold", marginRight: "8px", fontSize: isDesktop ? "16px" : "18px"}}
          component="span"
        >
          <FormattedMessage id="candidate.button_saved_jobs" />
        </Typography>
        <ArrowCircleRightOutlinedIcon />
      </Box>
    </Paper>
  );
};

export default SavedJobs;
