import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import {
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button,
  Modal,
  useMediaQuery,
  Paper,
} from "@mui/material";
import sh from "bundles/common/utils/sh";
import JobOfferCard from "bundles/Candidate/components/JobOfferCard";
import DashboardData from "bundles/Candidate/components/DashboardData";
import DashboardCarousel from "bundles/Candidate/components/DashboardCarousel";
import ProfileCompletion from "bundles/Candidate/components/ProfileCompletion";
import SavedJobs from "bundles/Candidate/components/SavedJobs";
import ProfileViews from "bundles/Candidate/components/ProfileViews";
import { trackSignup } from "bundles/common/utils/marketing";
import OnboardingModal from "./OnboardingModal";
import Poll from "bundles/common/components/Poll";
import SetPasswordModal from "bundles/common/components/SetPasswordModal";
import QuestionSnackbar from "bundles/common/components/QuestionSnackbar";
import JobOfferRecommendationBot from "bundles/Candidate/components/JobOfferRecommendationBot";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Candidate = () => {
  const railsEnv = useSelector(state => state.railsEnv);
  if (railsEnv === "production") trackSignup();

  const currentCandidate = useSelector(state => state.current_candidate);
  const candidateId = currentCandidate?.id;
  const authenticityToken = useSelector(state => state.authenticity_token);
  const i18nLocale = useSelector(state => state.i18nLocale);
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const query = useQuery();

  const [activePoll, setActivePoll] = useState(null);
  const [shouldShowPoll, setShouldShowPoll] = useState(false);
  const [jobOffers, setJobOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(
    query.get("showOnboarding") === "true" || false
  );

  const [isSetPasswordModalOpened, setIsSetPasswordModalOpened] = useState(
    (currentCandidate?.password_generated &&
      currentCandidate?.signup_origin == "landbot") ||
      false
  );

  const [shouldShowQuestionSnackbar, setShouldShowQuestionSnackbar] = useState(
    false
  );

  const [activeQuestion, setActiveQuestion] = useState(
    sessionStorage.getItem("activeQuestion") == "true" || false
  );

  const handleCloseOnboarding = () => {
    setShowOnboarding(false);
    setShouldShowQuestionSnackbar(!activeQuestion);
  };

  const fetchLastJobs = () => {
    setLoading(true);
    sh.get("last_n_jobs", {
      params: {
        quantity: 4,
        authenticity_token: authenticityToken,
      },
    })
      .then(res => {
        setJobOffers(res.data?.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchActivePoll = () => {
    setLoading(true);
    sh.get("/active_poll", {
      params: {
        authenticity_token: authenticityToken,
      },
    })
      .then(res => {
        setActivePoll(res.data.active_poll);
        setTimeout(() => {
          setShouldShowPoll(!!res.data.active_poll);
          setShouldShowQuestionSnackbar(
            !showOnboarding && !res.data.active_poll && !activeQuestion
          );
        }, 1000);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    sessionStorage.setItem("activeQuestion", activeQuestion);
  }, [activeQuestion]);

  useEffect(() => {
    if (currentCandidate) {
      fetchLastJobs();
      fetchActivePoll();
    } else {
      navigate({ pathname: `/${i18nLocale}/candidate/job_offers` });
    }
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      window.sessionStorage.clear();
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center" alignItems="center" mb="30px">
        {/* Header */}
        <Grid item xs={12}>
          <Box my={4}>
            <Typography align="center" variant="h2" gutterBottom style={{
              fontFamily: "Work Sans", fontSize: isDesktop ? "28px" : "24px", color: theme.palette.text.blue,
            }}>
              <b>
                <FormattedMessage id="candidate.welcome_dashboard" values={{ name: currentCandidate?.first_name }} />
              </b>
            </Typography>
            <Typography align="center" variant="h3" style={{ color: theme.palette.common.sh_grey }}>
              <FormattedMessage id="candidate.welcome_dashboard_sub" />
            </Typography>
          </Box>
        </Grid>

        {/* Three-column layout */}
        <Grid container item xs={12} spacing={2} justifyContent="center" alignItems="flex-start">

          {/* Left Paper Component */}
          {isDesktop && (
            <Grid item xs={12} md={3}>
              <Box >
                <DashboardData />
              </Box >
              <Box mt={2}>
                <SavedJobs candidateId={candidateId} />
              </Box>
              <Box mt={2}>
                <ProfileViews candidate={currentCandidate} />
              </Box>
            </Grid>
          )}

          {/* Center Paper Component with Job Offers */}
          <Grid item xs={12} md={5}>
            <Paper elevation={3} style={{ padding: "16px", borderRadius: "15px" }}>
              {loading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress size={24} color="secondary" />
                </Box>
              ) : (
                jobOffers.map((offer) => (
                  <Box key={offer.id} py={1}>
                    <JobOfferCard
                      offer={offer}
                      onClick={() => navigate(`/${i18nLocale}/candidate/job_offers?jid=${offer.id}`)}
                      active={false}
                      redirect={false}
                      logoHoverAndRedirect
                      compact={true}
                    />
                  </Box>
                ))
              )}
            </Paper>
            <Box style={{
              marginTop: "25px",
              marginBottom: "12px",
              display: "flex",
              justifyContent: "center",
            }}>
              <Button
                variant="rounded"
                color="primaryOutlined"
                onClick={() =>
                  navigate({
                    pathname: `/${i18nLocale}/candidate/job_offers`,
                  })
                }
                style={{ width: "70%" }}
              >
                <FormattedMessage id="job_offer_summary.see_more" />
              </Button>
            </Box>
          </Grid>

          {/* Right Paper Component */}
          <Grid item xs={12} md={4}>
            <Box>
              <ProfileCompletion />
            </Box>
            <Box mt={2}>
              <DashboardCarousel />
            </Box>
          </Grid>
        </Grid>

        {/* Saved Jobs Component for Mobile */}
        {!isDesktop && (
          <Grid item xs={12}>
            <Box mt={2} mb={4}>
              <SavedJobs candidateId={candidateId} />
            </Box>
          </Grid>
        )}

        {!isDesktop && (
          <Grid item xs={12}>
            <Box mt={2} mb={4}>
              <ProfileViews candidate={currentCandidate} />
            </Box>
          </Grid>
        )}

        <Modal
          open={showOnboarding}
          onClose={handleCloseOnboarding}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <OnboardingModal closeModal={() => setShowOnboarding(false)} />
        </Modal>

        <Modal
          open={isSetPasswordModalOpened}
          onClose={() => {}}
          aria-labelledby="set-password-modal-title"
          aria-describedby="set-password-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <SetPasswordModal closeModal={() => setIsSetPasswordModalOpened(false)} />
        </Modal>
      </Grid>
      {activePoll && shouldShowPoll && <Poll poll={activePoll} />}
      {shouldShowQuestionSnackbar && (
        <QuestionSnackbar setActiveQuestion={setActiveQuestion} />
      )}
      <JobOfferRecommendationBot />
    </Container>
  );
};

export default Candidate;
