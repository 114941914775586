import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import translations from "app/libs/i18n/translations.json";
import Recruiter from "../components/Recruiter";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import MainAppBarRecruiter from "bundles/Layouts/MainAppBarRecruiter";
import Footer from "bundles/Layouts/Footer";
import JobOfferCreation from "../components/JobOfferCreation";
import JobOfferIndex from "../components/JobOfferIndex";
import Faq from "../../common/components/Faq";
import OrderPage from "../components/OrderPage";
import OrderHistoryPage from "../components/OrderHistoryPage";
import CandidateIndex from "bundles/Recruiter/components/CandidateIndex";
import ApplicantIndex from "bundles/Recruiter/components/ApplicantIndex";
import ContactUs from "bundles/common/components/ContactUs";
// import JoinUs from "bundles/common/components/JoinUs";
import PrivacyPolicy from "bundles/common/components/PrivacyPolicy";
import Cookies from "bundles/common/components/Cookies";
import GeneralSalesConditions from "bundles/common/components/GeneralSalesConditions";
import SiteMap from "bundles/common/components/SiteMap";
import TermsOfUse from "bundles/common/components/TermsOfUse";
import ScrollToTop from "bundles/common/utils/scrollToTop";
import Godview from "bundles/common/components/Godview";
import Pricing from "../components/Pricing";
import Settings from "../components/Settings";
import Blog from "bundles/common/components/Blog";
import BlogView from "bundles/common/components/BlogView";
import { loadStripe } from "@stripe/stripe-js";
import consumer from "../channels/consumer";
import { Elements } from "@stripe/react-stripe-js";
import CompanyPage from "bundles/Candidate/components/CompanyPage";
import EmailConfirmationBanner from "../components/EmailConfirmationBanner";
import CompanyIndex from "bundles/Candidate/components/CompanyIndex";
import ProjectPage from "bundles/common/components/ProjectPage";

const RecruiterContainer = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const theme = useTheme();
  const dispatch = useDispatch();
  const messages = translations[i18nLocale];
  const godviewSessionMessage = currentRecruiter?.godview;
  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

  if (
    godviewSessionMessage == null &&
    !currentRecruiter?.email.includes("squarehub") &&
    typeof smartlook !== "undefined"
  ) {
    {
      smartlook("identify", `recruiter-${currentRecruiter?.id}`, {
        Company: currentRecruiter?.company.name,
        name: `${currentRecruiter?.first_name} ${currentRecruiter?.last_name}`,
        email: currentRecruiter?.email,
        country: currentRecruiter?.company?.country,
        address: `${currentRecruiter?.company?.address} + ${currentRecruiter?.company?.address_number}`,
      });
    }
  }

  const [footerRendered, setFooterRendered] = useState(false);

  const createSubscription = () => {
    consumer.subscriptions.create(
      { channel: "RecruiterChannel", id: currentRecruiter?.id },
      {
        received(data) {
          updateCurrentRecruiter(data);
        },
      }
    );
  };

  const updateCurrentRecruiter = useCallback(
    (recruiter) => {
      dispatch({ type: "UPDATE_CURRENT_RECRUITER", payload: recruiter });
    },
    [dispatch]
  );

  const resize = () => {
    let recruiterMainBox = document.getElementById("recruiter-main-box");
    let footerHeight = document.getElementById("client-sh-footer").offsetHeight;
    if (footerHeight > 0) {
      recruiterMainBox.style.minHeight = `calc(100vh - ${footerHeight}px)`;
    }
  };

  useEffect(() => {
    if (currentRecruiter?.id) {
      createSubscription();
    }
  }, [currentRecruiter?.id]);

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    if (footerRendered) {
      resize();
    }
  }, [footerRendered]);

  return (
    <IntlProvider i18nLocale={i18nLocale} key={i18nLocale} messages={messages}>
      <BrowserRouter>
        <Box
          id="recruiter-main-box"
          display="flex"
          flexDirection="column"
          style={{ minHeight: "100vh" }}
        >
          {godviewSessionMessage && <Godview message={godviewSessionMessage} />}
          <MainAppBarRecruiter style={{ flexGrow: 0 }} />
          {!currentRecruiter["email_confirmed?"] && <EmailConfirmationBanner />}
          <Box
            className={theme.background}
            style={{ flexGrow: "99" }}
            id="recruiter-container"
          >
            {/* <Container
            maxWidth="lg"
            className={theme.background}
            style={{ flexGrow: "99" }}
            id="recruiter-container"
          > */}
            <Elements stripe={stripePromise} style={{ width: "100%" }}>
              <ScrollToTop />
              <Routes>
                <Route
                  path={`/${i18nLocale}/recruiter/FAQ`}
                  element={
                    <Faq type="recruiter" userEmail={currentRecruiter.email} />
                  }
                />
                <Route
                  path={`/${i18nLocale}/recruiter/job_offer_creation`}
                  element={<JobOfferCreation />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/find_candidate`}
                  element={<CandidateIndex />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/my_candidates`}
                  element={<ApplicantIndex />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/job_offers`}
                  element={<JobOfferIndex />}
                />
                {/* <Route
                  path={`/${i18nLocale}/recruiter/order`}
                  element={<OrderPage />}
                /> */}
                <Route
                  path={`/${i18nLocale}/recruiter/order_history`}
                  element={<OrderHistoryPage />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/company`}
                  element={<CompanyPage />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/company/project/:id`}
                  element={<ProjectPage />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/company/project/:project_name/:id`}
                  element={<ProjectPage />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/contact-us`}
                  element={<ContactUs />}
                />
                {/* <Route
                  path={`/${i18nLocale}/recruiter/join-us`}
                  element={<JoinUs />}
                /> */}
                <Route
                  path={`/${i18nLocale}/recruiter/privacy-policy`}
                  element={<PrivacyPolicy />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/cookies`}
                  element={<Cookies />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/general-sales-conditions`}
                  element={<GeneralSalesConditions />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/site-map`}
                  element={<SiteMap />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/terms-of-use`}
                  element={<TermsOfUse />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/pricing`}
                  element={<Pricing />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/settings*`}
                  element={<Settings />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/settings`}
                  element={<Settings />}
                />
                <Route
                  path={`/${i18nLocale}/candidate/posts/:blog_title/:id`}
                  element={<BlogView type="candidate" />}
                />
                <Route
                  path={`/${i18nLocale}/candidates/posts/:blog_title/:id`}
                  element={<BlogView type="candidate" />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/posts/:blog_title/:id`}
                  element={<BlogView type="recruiter" />}
                />
                <Route
                  path={`/${i18nLocale}/recruiters/posts/:blog_title/:id`}
                  element={<BlogView type="recruiter" />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/posts/:id`}
                  element={<BlogView type="recruiter" />}
                />
                <Route
                  path={`/${i18nLocale}/recruiters/posts/:id`}
                  element={<BlogView type="recruiter" />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter/posts`}
                  element={<Blog type="recruiter" />}
                />
                <Route
                  path={`/${i18nLocale}/recruiters/posts`}
                  element={<Blog type="recruiter" />}
                />
                <Route
                  path={`/${i18nLocale}/trusted_companies`}
                  element={<CompanyIndex />}
                />
                <Route
                  path={`/${i18nLocale}/recruiter`}
                  element={<Recruiter />}
                />
                <Route
                  path={`/${i18nLocale}/`}
                  element={<Navigate to={`/${i18nLocale}/recruiter`} />}
                />
              </Routes>
            </Elements>
          </Box>
        </Box>
      </BrowserRouter>
      <Footer setFooterRendered={setFooterRendered} />
    </IntlProvider>
  );
};

export default RecruiterContainer;
