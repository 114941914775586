import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import {
  Hidden,
  Grid,
  Box,
  Link,
  IconButton,
  Typography,
  useMediaQuery,
  Modal,
  List,
  ListItem,
} from "@mui/material";
import Logo from "icons/logo.svg";
import { BrowserRouter } from "react-router-dom";
import PricingModal from "bundles/LandingPageRecruiter/components/PricingModal";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

function SocialMedias() {
  const theme = useTheme();
  return (
    <Box mb={2} textAlign="center">
      <IconButton
        aria-label="Facebook"
        href="https://www.facebook.com/squarehubeu"
        target="_blank"
      >
        <Facebook fontSize="medium" style={{ color: theme.palette.text.blue }} />
      </IconButton>
      <IconButton
        aria-label="LinkedIn"
        href="https://www.linkedin.com/company/squarehub-eu/"
        target="_blank"
      >
        <LinkedIn fontSize="medium" style={{ color: theme.palette.text.blue }} />
      </IconButton>
      <IconButton
        aria-label="Instagram"
        href="https://www.instagram.com/squarehub.eu/"
        target="_blank"
      >
        <Instagram fontSize="medium" style={{ color: theme.palette.text.blue }} />
      </IconButton>
    </Box>
  );
}

function FooterLink({ href, messageId }) {
  return (
    <Box mx={1}>
      <Typography variant="body2" gutterBottom>
        <Link href={href} style={{ color: "white" }} underline="hover">
          <FormattedMessage id={messageId} />
        </Link>
      </Typography>
    </Box>
  );
}

function FooterLinks({ getUrl, isCandidatePage }) {
  return (
    <>
      <FooterLink
        messageId="footer.terms_of_use.terms_of_use"
        href={getUrl("terms-of-use")}
      />
      {!isCandidatePage && (
        <FooterLink
          messageId="footer.general_sales_conditions.general_sales_conditions"
          href={getUrl("general-sales-conditions")}
        />
      )}
      <FooterLink
        messageId="footer.privacy_policy.privacy_policy"
        href={getUrl("privacy-policy")}
      />
      <FooterLink
        messageId="footer.site_map.site_map"
        href={getUrl("site-map")}
      />
    </>
  );
}

// --------------
// Candidate Sub-Column
// --------------
function CandidateColumn({ i18nLocale }) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box my={2}>
        <List>
          <ListItem>
            <Typography variant="h4">
              <b>
                <FormattedMessage id="menu.candidates" />
              </b>
            </Typography>
          </ListItem>
          <ListItem>
            <Link
              href={`/${i18nLocale}/candidate/settings`}
              color="textPrimary"
              underline="hover"
            >
              <FormattedMessage id="profile.my_settings" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href={`/${i18nLocale}/candidate/job_offers?selectedTab=my_jobs`}
              color="textPrimary"
              underline="hover"
            >
              <FormattedMessage id="job_search.my_jobs" />
            </Link>
          </ListItem>
        </List>
      </Box>
    </Grid>
  );
}

// --------------
// Recruiter Sub-Column
// --------------
function RecruiterColumn({ i18nLocale }) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box my={2}>
        <List>
          <ListItem>
            <Typography variant="h4">
              <b>
                <FormattedMessage id="menu.recruiters" />
              </b>
            </Typography>
          </ListItem>
          <ListItem>
            <Link
              href={`/${i18nLocale}/recruiter/job_offers`}
              color="textPrimary"
              underline="hover"
            >
              <FormattedMessage id="menu.jobs" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href={`/${i18nLocale}/recruiter/job_offer_creation`}
              color="textPrimary"
              underline="hover"
            >
              <FormattedMessage id="menu.post_job_offer" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href={`/${i18nLocale}/recruiter/find_candidate`}
              color="textPrimary"
              underline="hover"
            >
              <FormattedMessage id="candidate_search.find_candidate" />
            </Link>
          </ListItem>
        </List>
      </Box>
    </Grid>
  );
}

// --------------
// Recruiter Unauthenticated Column
// --------------
function RecruiterUnauthColumn({
  i18nLocale,
  isRecruiterPage,
  showModal,
  setShowModal,
}) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box my={2}>
        <List>
          <ListItem>
            <Typography variant="h4">
              <b>
                <FormattedMessage id="recruiter.recruiter" />
              </b>
            </Typography>
          </ListItem>
          <ListItem>
            <Link
              href={`/${i18nLocale}/recruiter/job_offer_creation`}
              color="textPrimary"
              underline="hover"
            >
              <FormattedMessage id="job_offer.post_new_job" />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href={`/${i18nLocale}/recruiters/sign_up`}
              color="textPrimary"
              underline="hover"
            >
              <FormattedMessage id="landing_recruiter.account.create_account" />
            </Link>
          </ListItem>
          {isRecruiterPage && (
            <ListItem>
              <Link
                href="#"
                onClick={() => setShowModal(true)}
                color="textPrimary"
                underline="hover"
              >
                <FormattedMessage id="footer.download_prices" />
              </Link>
              <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <PricingModal closeModal={() => setShowModal(false)} />
              </Modal>
            </ListItem>
          )}
        </List>
      </Box>
    </Grid>
  );
}

// --------------
// Help Column
// --------------
function HelpColumn({ i18nLocale, connected, getUrl, isRecruiterPage, currentRecruiter }) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box my={2}>
        <List>
          <ListItem>
            <Typography variant="h4">
              <b>
                <FormattedMessage id="footer.help" />
              </b>
            </Typography>
          </ListItem>
          {connected ? (
            <>
              <ListItem>
                <Link
                  href={`/${i18nLocale}/${
                    currentRecruiter ? "recruiter" : "candidate"
                  }/FAQ`}
                  color="textPrimary"
                  underline="hover"
                >
                  <FormattedMessage id="footer.faq" />
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href={getUrl("contact-us")}
                  color="textPrimary"
                  underline="hover"
                >
                  <FormattedMessage id="contact_us" />
                </Link>
              </ListItem>
            </>
          ) : (
            <ListItem>
              <Link
                href={`/${i18nLocale}${
                  isRecruiterPage ? "/recruiters" : ""
                }/faq`}
                color="textPrimary"
                underline="hover"
              >
                <FormattedMessage id="footer.faq" />
              </Link>
            </ListItem>
          )}
          {isRecruiterPage && !currentRecruiter && (
            <ListItem>
              <Link
                href="https://calendly.com/demo-squarehub/quick-demo-30-min"
                color="textPrimary"
                underline="hover"
              >
                <FormattedMessage id="landing_recruiter.banner.button" />
              </Link>
            </ListItem>
          )}
          {isRecruiterPage && currentRecruiter && (
            <ListItem>
              <Link
                href={`/${i18nLocale}/recruiter/pricing`}
                color="textPrimary"
                underline="hover"
              >
                <FormattedMessage id="footer.pricing" />
              </Link>
            </ListItem>
          )}
        </List>
      </Box>
    </Grid>
  );
}

// --------------
// "Go to Candidate" or "Go to Recruiter" Column
// --------------
function SwitchUserColumn({ i18nLocale, isRecruiterPage }) {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box my={2}>
        <List>
          <ListItem>
            <Typography variant="h4">
              <b>
                {isRecruiterPage ? (
                  <FormattedMessage id="menu.candidates" />
                ) : (
                  <FormattedMessage id="menu.recruiters" />
                )}
              </b>
            </Typography>
          </ListItem>
          {isRecruiterPage ? (
            <ListItem>
              <Link
                href={`/${i18nLocale}/candidates/sign_in`}
                color="textPrimary"
                underline="hover"
              >
                <FormattedMessage id="nav.recruiter.go_to_candidate" />
              </Link>
            </ListItem>
          ) : (
            <ListItem>
              <Link
                href={`/${i18nLocale}/recruiters`}
                color="textPrimary"
                underline="hover"
              >
                <FormattedMessage id="footer.go_to_recruiter" />
              </Link>
            </ListItem>
          )}
        </List>
      </Box>
    </Grid>
  );
}

// --------------
// MAIN FOOTER
// --------------
export default function Footer({ setFooterRendered }) {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const isCandidatePage =
    (currentCandidate || !window.location.pathname.includes("recruiter")) &&
    !currentRecruiter;
  const isRecruiterPage =
    (currentRecruiter || window.location.pathname.includes("recruiter")) &&
    !currentCandidate;

  const [showModal, setShowModal] = useState(false);
  const connected = Boolean(currentCandidate || currentRecruiter);

  const userType = currentCandidate ? "candidate" : "recruiter";

  function getUrl(target) {
    return connected
      ? `/${i18nLocale}/${userType}/${target}`
      : `/${i18nLocale}/${target}`;
  }

  useEffect(() => {
    if (setFooterRendered) {
      setFooterRendered(true);
    }
  }, [setFooterRendered]);

  return (
    <BrowserRouter>
      <Grid container direction="column" id="client-sh-footer">
        <Grid item style={{ backgroundColor: "white" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              md={4}
              style={{ textAlign: "center" }}
            >
              <Box my={4}>
                <img
                  id="logo-squarehub"
                  alt="Squarehub"
                  width="100%"
                  height="auto"
                  style={{ maxWidth: 250 }}
                  src={Logo}
                />
              </Box>
              <Hidden smDown>
                <SocialMedias />
              </Hidden>
            </Grid>

            {/* Right Side: Condition-based columns */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {/* Candidate Authenticated */}
                {isDesktop && isCandidatePage && currentCandidate && (
                  <CandidateColumn i18nLocale={i18nLocale} />
                )}

                {/* If on candidate page, add “Go to recruiter” link */}
                {isDesktop && isCandidatePage && (
                  <SwitchUserColumn
                    i18nLocale={i18nLocale}
                    isRecruiterPage={false}
                  />
                )}

                {/* Recruiter Authenticated */}
                {isDesktop && currentRecruiter && (
                  <RecruiterColumn i18nLocale={i18nLocale} />
                )}

                {/* Recruiter Unauthenticated */}
                {isDesktop && !currentRecruiter && (
                  <RecruiterUnauthColumn
                    i18nLocale={i18nLocale}
                    isRecruiterPage={isRecruiterPage}
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                )}

                {/* Help Column always displayed on desktop */}
                {isDesktop && (
                  <HelpColumn
                    i18nLocale={i18nLocale}
                    connected={connected}
                    getUrl={getUrl}
                    isRecruiterPage={isRecruiterPage}
                    currentRecruiter={currentRecruiter}
                  />
                )}

                {/* If on recruiter page, add “Go to candidate” link */}
                {isDesktop && isRecruiterPage && (
                  <SwitchUserColumn
                    i18nLocale={i18nLocale}
                    isRecruiterPage={true}
                  />
                )}

                {/* Mobile social media icons */}
                <Hidden mdUp>
                  <Grid item xs={12}>
                    <Box textAlign="center" mt={2}>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <FormattedMessage id="join_social_medias" />
                      </Typography>
                      <SocialMedias />
                    </Box>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ backgroundColor: theme.palette.text.blue }}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
              <Box py={1}>
                <Typography
                  align="center"
                  style={{ color: "white", fontStyle: "italic" }}
                  variant="body3"
                >
                  &copy; Squarehub {new Date().getFullYear()}.{" "}
                  <FormattedMessage id="footer.all_rights_reserved" />
                </Typography>
              </Box>
            </Grid>

            <Hidden mdUp>
              <Grid item xs={12}>
                <Box my={2} textAlign="center">
                  <FooterLinks isCandidatePage={isCandidatePage} getUrl={getUrl} />
                </Box>
              </Grid>
            </Hidden>

            <Hidden smDown>
              <Grid item md={8}>
                <Box display="flex" justifyContent="flex-end" pr={4}>
                  <FooterLinks isCandidatePage={isCandidatePage} getUrl={getUrl} />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </BrowserRouter>
  );
}
