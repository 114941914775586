import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import JobOfferCardDesktop from "./Desktop/JobOfferCardDesktop";
import JobOfferCardMobile from "./Mobile/JobOfferCardMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sh from "bundles/common/utils/sh";
import Architecture from "icons/architecture.svg";
import Engineering from "icons/engineering.svg";
import Construction from "icons/tools.svg";
import Administration from "icons/administration.svg";

const JobOfferCard = ({
  offer,
  onClick,
  active,
  key,
  redirect,
  logoHoverAndRedirect,
  compact,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const currentCandidate = useSelector((state) => state.current_candidate);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const dispatch = useDispatch();

  const [currentFavorites, setCurrentFavorites] = useState(
    currentCandidate?.favorite_offers || []
  );

  const isFavorite = currentFavorites.includes(`${offer?.id}`);

  const updatecurrentCandidate = useCallback(
    (candidate) => {
      dispatch({ type: "UPDATE_CURRENT_CANDIDATE", payload: candidate });
    },
    [dispatch]
  );

  const handleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!currentCandidate) {
      return onClick(offer, e, true);
    }

    let currentFavorites;
    if (isFavorite) {
      currentFavorites = currentCandidate?.favorite_offers.filter(
        (favorite) => favorite != `${offer?.id}`
      );
    } else {
      currentFavorites = currentCandidate?.favorite_offers.concat([offer?.id]);
    }

    sh.put("update_favorite_offers", {
      authenticity_token: authenticityToken,
      favorite_offers: currentFavorites,
    }).then((res) => {
      if (res.data) {
        setCurrentFavorites(res.data.favorite_offers);
        updatecurrentCandidate(res.data);
      }
      if (compact) {
        window.location.reload();
      }
    });
  };

  const industryImageMapping = {
    architecture: Architecture,
    engineering: Engineering,
    construction: Construction,
    administration: Administration,
  };

  useEffect(() => {
    setCurrentFavorites(currentCandidate?.favorite_offers || []);
  }, [currentCandidate?.favorite_offers]);

  return (
    <>
      {isDesktop
        ?
        <JobOfferCardDesktop
          offer={offer}
          onClick={onClick}
          active={active}
          key={key}
          redirect={redirect}
          logoHoverAndRedirect={logoHoverAndRedirect}
          handleFavorite={handleFavorite}
          isFavorite={isFavorite}
          industryImageMapping={industryImageMapping}
          compact={compact}
        />
        :
        <JobOfferCardMobile
          offer={offer}
          onClick={onClick}
          active={active}
          key={key}
          redirect={redirect}
          logoHoverAndRedirect={logoHoverAndRedirect}
          handleFavorite={handleFavorite}
          isFavorite={isFavorite}
          industryImageMapping={industryImageMapping}
          compact={compact}
        />
      }
    </>
  );
};

export default JobOfferCard;
