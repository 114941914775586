import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { Paper, Typography, Link, Box } from "@mui/material";
import sh from "bundles/common/utils/sh";
import { FormattedMessage } from "react-intl";

const DashboardData = () => {
  const [jobOffersCount, setJobOffersCount] = useState(0);
  const [companyCount, setCompanyCount] = useState(0);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    sh.get("candidate_dashboard_data")
      .then((res) => {
        setCompanyCount(res.data.companies_count);
        setJobOffersCount(res.data.job_offers_count);
        setApplicationsCount(res.data.applications_count);
      })
      .catch((err) => {
        console.error("Error fetching dashboard data:", err);
      });
  }, []);

  return (
    <Paper elevation={3} style={{ padding: "16px", borderRadius: "15px" }}>
      <Box mb={2}>
        <Link
          component="button"
          sx={{ textDecoration: "none" }}
          onClick={() => navigate(`/${i18nLocale}/candidate/job_offers`)}
        >
          <Typography variant="body1bold" style={{ color: theme.palette.text.blue }}>
            <FormattedMessage id="candidate.job_offers" values={{ count: jobOffersCount }} />
          </Typography>
        </Link>
      </Box>
      <Box mb={2}>
        <Link
          component="button"
          sx={{ textDecoration: "none" }}
          onClick={() => navigate(`/${i18nLocale}/companies`)}
        >
          <Typography variant="body1bold" style={{ color: theme.palette.text.blue }}>
            <FormattedMessage id="candidate.companies" values={{ count: companyCount }} />
          </Typography>
        </Link>
      </Box>
      <Box mb={2}>
        <Link
          component="button"
          sx={{ textDecoration: "none" }}
          onClick={() =>
            navigate(`/${i18nLocale}/candidate/job_offers?selectedTab=my_jobs`)
          }
        >
          <Typography variant="body1bold" style={{ color: theme.palette.text.blue }}>
            <FormattedMessage id="candidate.my_applications" values={{ count: applicationsCount }} />
          </Typography>
        </Link>
      </Box>
      <Box mb={2}>
        <Link
          component="button"
          sx={{ textDecoration: "none" }}
          onClick={() =>
            navigate(`/${i18nLocale}/candidate/settings?selectedSection=resume_generator`)
          }
        >
          <Typography
            variant="body1bold"
            style={{ color: theme.palette.text.blue }}
          >
            <FormattedMessage id="candidate.cv" />
          </Typography>
        </Link>
      </Box>
      <Box mb={2}>
        <Link
          component="button"
          sx={{ textDecoration: "none" }}
          onClick={() => navigate(`/${i18nLocale}/candidate/settings`)}
        >
          <Typography
            variant="body1bold"
            style={{ color: theme.palette.text.blue }}
          >
            <FormattedMessage id="candidate.settings" />
          </Typography>
        </Link>
      </Box>
    </Paper>
  );
};

export default DashboardData;
