import React from "react";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/styles";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";


const ProfileViews = ({ candidate }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const candidateInterestsCount = candidate?.candidate_interests_count;

  return (
    <Paper elevation={3} style={{ padding: "16px", borderRadius: "15px" }}>
      <Box mb={2} style={{ display: isDesktop ? "unset" : "flex", justifyContent: isDesktop ? "unset" : "center" }}>
        <Typography variant="h5" gutterBottom
          style={{ fontWeight: "bold", fontSize: isDesktop ? "18px" : "24px" }}
        >
          <FormattedMessage
            id="candidate_summary.views"
          />
        </Typography>
      </Box>
      <Box style={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
        <VisibilityIcon style={{ color: theme.palette.text.blue }} />
        <Typography
          variant="body2bold"
          style={{ color: theme.palette.text.blue }}
        >
          <FormattedMessage
            id="candidate.profile_views"
            values={{ count: candidateInterestsCount }}
          />
        </Typography>
      </Box>
    </Paper>
  );
};

export default ProfileViews;
