import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Box, useMediaQuery, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const CardSelect = ({
  fieldName,
  selected,
  values,
  onChange,
  color = "secondary",
  multiple = false,
  ...props
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const selectedValues = multiple ? selected : [selected];
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const handleOptionClicked = (code) => {
    onChange(fieldName, code, multiple);
  };
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      columnGap: "24px",
      overflow: "auto",
      justifyContent: props.justifyContent,
    },
    option: {
      border: `1px solid ${theme.palette.common.sh_grey}`,
      height: "56px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "24px",
      paddingRight: "24px",
      cursor: "pointer",
      whiteSpace: "nowrap",
      flexGrow: 1,
      justifyContent: "center",
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        borderColor: theme.palette.text[color],
      },
    },
    mobileOption: {
      border: `1px solid ${theme.palette.common.sh_grey}`,
      height: "56px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "24px",
      paddingRight: "24px",
      cursor: "pointer",
      whiteSpace: "wrap",
      "&:hover": {
        borderColor: theme.palette.text[color],
      },
    },
    selectedOption: {
      color: theme.palette.common.white,
      backgroundColor: currentRecruiter
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
      borderColor: currentRecruiter
        ? theme.palette[color].dark
        : theme.palette.primary.main,
    },
  }));

  const classes = useStyles();
  return isDesktop ? (
    <Box className={classes.container}>
      {values.map((value) => (
        <Box
          key={value.code}
          className={`${classes.option} ${
            selectedValues.includes(value.code) ? classes.selectedOption : ""
          }`}
          onClick={() => handleOptionClicked(value.code)}
        >
          <Typography variant="body2bold">{value.label}</Typography>
        </Box>
      ))}
    </Box>
  ) : (
    <Grid container spacing={1}>
      {values.map((value) => (
        <Grid item xs={6} key={value.code}>
          <Box
            className={`${classes.mobileOption} ${
              selectedValues.includes(value.code) ? classes.selectedOption : ""
            }`}
            onClick={() => handleOptionClicked(value.code)}
          >
            <Typography variant="body2bold" textAlign="center">
              {value.label}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardSelect;
