import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Box, Typography, Modal, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import CompanyCard from "bundles/Recruiter/components/CompanyCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SpontaneousApplicationDialog from "bundles/Candidate/components/SpontaneousApplicationDialog";
import sh from "bundles/common/utils/sh";

const DashboardCarousel = () => {
  const [companies, setCompanies] = useState([]);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [isApplying, setIsApplying] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const getJobApplicationForCompany = (companyId) => {
    return currentCandidate?.job_applications?.find(
      (jobApp) =>
        !jobApp.job_offer_id && jobApp.company_id == companyId
    );
  };

  const useStyles = makeStyles(() => ({
    carouselContainer: {
      width: "100%",
      position: "relative",
      marginBottom: isDesktop ? "40px" : "20px",
      marginTop: isDesktop ? "0px" : "30px"
    },
    item: {
      width: "100%",
      maxWidth: "275px",
      margin: "0 auto",
    },
    itemWrapper: {
      position: "relative",
    },
    navButtonsWrapper: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      "&:nth-of-type(1)": {
        left: "0",
      },
      "&:nth-of-type(2)": {
        right: "0",
      },
      zIndex: 1,
    },
    navButtons: {
      backgroundColor: "transparent !important",
      color: "black",
      "&:hover": {
        backgroundColor: "transparent",
      },
      opacity: 1,
      left: "3px",
    },
  }));

  const classes = useStyles();

  const fetchCompanies = () => {
    sh.get("company_dash", {
      params: {
        authenticity_token: authenticityToken,
      },
    })
      .then((res) => {
        const allCompanies = res.data;

        const filteredCompanies = allCompanies.filter(
          (company) => company.match_percentage >= 60
        );

        setCompanies(filteredCompanies);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleSpontaneousApplyClick = (companyId) => {
    setSelectedCompanyId(companyId);
    setIsApplying(true);
  };

  const closeApplicationModal = () => {
    setIsApplying(false);
    setSelectedCompanyId(null);
  };

  return (
    <Box className={classes.carouselContainer}>
      <Box style={{ marginBottom: "20px" }}>
        <Typography variant="h4"
          style={{
            fontWeight: "bold",
            fontSize: isDesktop ? "22px" : "1.5rem",
            display: "flex",
            justifyContent: isDesktop ? "left" : "center",
          }}>
          <FormattedMessage id="candidate.carousel_title" />
        </Typography>
        <Typography variant="h6"
          style={{
            fontSize: isDesktop ? "16px" : "1rem",
            display: "flex",
            justifyContent: isDesktop ? "left" : "center",
            marginTop: isDesktop ? "0px" : "15px",
            marginBottom: isDesktop ? "0px" : "10px",
          }}>
          <FormattedMessage id="candidate.carousel_subtitle" />
        </Typography>
      </Box>
      <Carousel
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosIcon />}
        navButtonsProps={{
          className: classes.navButtons,
        }}
        navButtonsWrapperProps={{
          className: classes.navButtonsWrapper,
        }}
        animation={"slide"}
        autoPlay={false}
        indicators={false}
      >
        {companies.map((company, index) => (
          <Box className={classes.itemWrapper} key={`carousel-company-${index}`}>
            <Box className={classes.item}>
              <CompanyCard
                company={company}
                compact={true}
                onClick={() => {
                  window.location.href = `/${i18nLocale}/companies/${company.encoded_name}/${company.id}`;
                }}
                handleSpontaneousApplyClick={() =>
                  handleSpontaneousApplyClick(company.id)
                }
              />
            </Box>
          </Box>
        ))}
      </Carousel>
      {isApplying && selectedCompanyId && (
        <Modal
          hideBackdrop={false}
          open={isApplying}
          onClose={closeApplicationModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center" }}
        >
          <SpontaneousApplicationDialog
            companyId={selectedCompanyId}
            closeModal={closeApplicationModal}
            jobApplication={getJobApplicationForCompany(selectedCompanyId)}
          />
        </Modal>
      )}
    </Box>
  );
};

export default DashboardCarousel;
