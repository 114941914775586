import React from "react";
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CandidateSummary from "bundles/common/components/CandidateSummary";

const CandidatePreviewModal = ({ isOpen, setIsOpen, candidate }) => {
  const useStyles = makeStyles(() => ({
    dialog: {
      "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
          borderRadius: "30px ",
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={classes.dialog}
    >
      <CandidateSummary candidate={candidate} blur={false} />
    </Dialog>
  );
};

export default CandidatePreviewModal;
