import React from "react";
import {
  Grid,
  Typography,
  Chip,
  Box,
  Paper,
  IconButton,
  Link,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useSelector } from "react-redux";
import { formatJobOfferLocations } from "bundles/common/utils/utils";
import { truncate } from "bundles/common/utils/utils";
import { FormattedMessage } from "react-intl";
import TwoPeople from "icons/two_people.svg";

const JobOfferCardDesktop = ({
  offer,
  onClick,
  active,
  key,
  redirect = false,
  logoHoverAndRedirect = false,
  handleFavorite,
  isFavorite,
  industryImageMapping,
  compact = false,
}) => {
  const theme = useTheme();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const useStyles = makeStyles((theme) => ({
    cardContainer: {
      padding: compact ? "4px 4px" : "6px",
      minHeight: compact ? "auto" : "100%",
      cursor: offer.offer_status == "online" ? "pointer" : "default",
      backgroundColor:
        offer.offer_status == "online" ? theme.palette.common.white : "#E8E8E8",
      border: "2px solid transparent",
      transition: "border 0.3s ease !important",
      "&:hover": {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      "&::after": compact
        ? {
          content: "\"\"",
          position: "absolute",
          left: "10px",
          right: "10px",
          bottom: -0.5,
          height: "1px",
          backgroundColor: theme.palette.common.sh_grey,
        }
        : {},
      transform: compact ? "scale(1.01)" : "unset",
      boxShadow: compact
        ? "none"
        : "unset",
    },
    cardContainerActive: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    jobTitle: {
      fontSize: compact ? "14px" : "16px",
      wordBreak: "break-word",
    },
    companyName: {
      fontSize: compact ? "12px" : "14px",
      color: "black",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Box
        component={redirect ? "a" : "div"}
        href={`${window.location.origin}/${i18nLocale}/jobs/${offer?.encoded_job_title}/${offer?.id}`}
        style={{ all: "unset" }}
      >
        <Grid
          container
          component={Paper}
          className={`${classes.cardContainer} ${
            active ? classes.cardContainerActive : ""
          }`}
          onClick={(e) => {
            if (offer.offer_status == "online") {
              onClick(offer, e);
            }
          }}
          key={key}
        >
          <Grid item xs={12} className={classes.paperUp}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Box
                display="flex"
                alignItems="center"
                style={{ columnGap: "16px"}}
              >
                <Box
                  style={{
                    width: "70px",
                    height: "70px",
                    overflow: "hidden",
                    border: "1px solid #b8b8b8",
                  }}
                  className={`${
                    logoHoverAndRedirect ? classes.logoHover : ""
                  }`}
                >
                  <Link
                    href={`/companies/${offer?.company?.name}/${offer?.company?.id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ProfilePicture
                      picture={offer?.company?.logo_url}
                      picturesmall={offer?.company?.small_logo_url}
                      size={70}
                      initials={offer?.company?.name[0]}
                      alt={`Logo ${offer?.company?.name}`}
                      style={{ borderRadius: "none" }}
                    />
                  </Link>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: compact ? "350px" : "450px",
                    }}
                  >
                    <Box>
                      <Box>
                        <Typography
                          variant="body1bold"
                          style={{ wordBreak: "break-word" }}
                        >
                          {truncate(offer?.job_title, 52)}
                        </Typography>
                      </Box>
                      <Box mb={-0.6}>
                        <Typography variant="body2" style={{ color: "black" }}>
                          {truncate(offer?.company?.name, 60)}
                        </Typography>
                      </Box>
                      <Box mb={0.4}>
                        <Typography
                          variant="body3"
                          style={{ textTransform: "capitalize" }}
                        >
                          {formatJobOfferLocations(offer?.locations || []).join(
                            ", "
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignSelf: "stretch",
                      }}
                    >
                      {offer.offer_status == "online" ? (
                        <IconButton
                          aria-label="edit-personal-info"
                          onClick={handleFavorite}
                        >
                          {isFavorite ? (
                            <FavoriteIcon
                              style={{
                                color: theme.palette.text.blue,
                                width: "32px",
                                height: "32px",
                              }}
                            />
                          ) : (
                            <FavoriteBorderIcon
                              style={{
                                color: theme.palette.text.blue,
                                width: "32px",
                                height: "32px",
                              }}
                            />
                          )}
                        </IconButton>
                      ) : (
                        <Chip
                          color="secondary"
                          label={<FormattedMessage id="job_offer.job_closed" />}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", columnGap: "16px"}}>
                    {offer?.company_industries?.length > 0 ? (
                      offer.company_industries.slice(0, 3).map((industry, index) => (
                        <Box
                          display="flex"
                          alignItems="center"
                          key={index}
                        >
                          <img
                            src={industryImageMapping[industry]}
                            alt={`${industry} icon`}
                            width="12"
                            height="12"
                            style={{
                              filter: "invert(100%)",
                              marginRight: "2px",
                            }}
                          />
                          <Typography
                            variant="body3bold"
                            style={{ textTransform: "capitalize" }}
                          >
                            {industry}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      offer?.company_industries.length > 0 && (
                        <Box
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={
                              industryImageMapping[
                                offer.company_industries[0]
                              ]
                            }
                            alt={`${offer.company_industries[0]} icon`}
                            width="12"
                            height="12"
                            style={{
                              filter: "invert(100%)",
                              marginRight: "4px",
                            }}
                          />
                          <Typography
                            variant="body3bold"
                            style={{ textTransform: "capitalize" }}
                          >
                            {offer.company_industries[0]}
                          </Typography>
                        </Box>
                      )
                    )}
                    {offer?.job_applications.length === 0 && (
                      <Box display="flex" alignItems="center">
                        <img
                          src={TwoPeople}
                          alt="Two People"
                          style={{ marginRight: "4px" }}
                        />
                        <Typography
                          variant="body3bold"
                          color="primary"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <FormattedMessage id="job_offer.be_the_first" />
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default JobOfferCardDesktop;
