import React from "react";
import {
  Grid,
  Typography,
  Chip,
  Box,
  Paper,
  IconButton,
  Link,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useSelector } from "react-redux";
import { formatJobOfferLocations } from "bundles/common/utils/utils";
import { truncate } from "bundles/common/utils/utils";
import { FormattedMessage } from "react-intl";
import TwoPeople from "icons/two_people.svg";

const JobOfferCardMobile = ({
  offer,
  onClick,
  active,
  key,
  redirect = false,
  logoHoverAndRedirect = false,
  handleFavorite,
  isFavorite,
  industryImageMapping,
  compact = false,
}) => {
  const theme = useTheme();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const useStyles = makeStyles((theme) => ({
    cardContainer: {
      padding: "8px 8px",
      cursor: offer.offer_status == "online" ? "pointer" : "default",
      backgroundColor:
        offer.offer_status == "online" ? theme.palette.common.white : "#E8E8E8",
      border: "2px solid transparent",
      transition: "border 0.3s ease !important",
      minHeight: "100%",
      "&:hover": {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    cardContainerActive: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    logoStyle: {
      border: "1px solid #b8b8b8",
      borderRadius: "0px",
    },
    logoHover: {
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Box
        component={redirect ? "a" : "div"}
        href={`${window.location.origin}/${i18nLocale}/jobs/${offer?.encoded_job_title}/${offer?.id}`}
        style={{ all: "unset" }}
      >
        <Grid
          container
          component={Paper}
          className={`${classes.cardContainer} ${
            active ? classes.cardContainerActive : ""
          }`}
          onClick={(e) => {
            if (offer.offer_status == "online") {
              onClick(offer, e);
            }
          }}
          key={key}
        >
          <Grid item xs={12} className={classes.paperUp}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Box
                display="flex"
                alignItems="center"
                style={{ columnGap: "4px" }}
              >
                <Box
                  style={{ marginLeft: "8px" }}
                  className={`${classes.logoStyle} ${
                    logoHoverAndRedirect ? classes.logoHover : ""
                  }`}
                >
                  <Link
                    href={`/companies/${offer?.company?.name}/${offer?.company?.id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ProfilePicture
                      picture={offer?.company?.logo_url}
                      picturesmall={offer?.company?.small_logo_url}
                      size={70}
                      initials={offer?.company?.name[0]}
                      alt={`Logo ${offer?.company?.name}`}
                    />
                  </Link>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "250px",
                    }}
                  >
                    <Box>
                      <Box mb={-0.6}>
                        <Typography
                          variant="body1bold"
                          style={{ wordBreak: "break-word" }}
                        >
                          {truncate(offer?.job_title, 36)}
                        </Typography>
                      </Box>
                      <Box mb={-0.6}>
                        <Typography variant="body2bold" style={{ color: "black" }}>
                          {truncate(offer?.company?.name, 30)}
                        </Typography>
                      </Box>
                      <Box mb={0.4}>
                        <Typography
                          variant="body3"
                          style={{ textTransform: "capitalize" }}
                        >
                          {formatJobOfferLocations(offer?.locations || []).join(
                            ", "
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignSelf: "stretch",
                      }}
                    >
                      {offer.offer_status == "online" ? (
                        <IconButton
                          aria-label="edit-personal-info"
                          onClick={handleFavorite}
                          style={{ marginRight: compact ? "14px" : "unset" }}
                        >
                          {isFavorite ? (
                            <FavoriteIcon
                              style={{
                                color: theme.palette.text.blue,
                                width: "32px",
                                height: "32px",
                              }}
                            />
                          ) : (
                            <FavoriteBorderIcon
                              style={{
                                color: theme.palette.text.blue,
                                width: "32px",
                                height: "32px",
                              }}
                            />
                          )}
                        </IconButton>
                      ) : (
                        <Chip
                          color="secondary"
                          label={<FormattedMessage id="job_offer.job_closed" />}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", columnGap: "16px"}}>
                    {offer?.company_industries.length > 0 && (
                      <Box
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={industryImageMapping[offer.company_industries[0]]}
                          alt={`${offer.company_industries[0]} icon`}
                          width="12"
                          height="12"
                          style={{
                            filter: "invert(100%)",
                            marginRight: "4px",
                          }}
                        />
                        <Typography
                          variant="body3bold"
                          style={{ textTransform: "capitalize" }}
                        >
                          {offer.company_industries[0]}
                        </Typography>
                      </Box>
                    )}
                    {offer?.job_applications.length === 0 && (
                      <Box display="flex" alignItems="center">
                        <img
                          src={TwoPeople}
                          alt="Two People"
                          style={{ marginRight: "4px" }}
                        />
                        <Typography
                          variant="body3bold"
                          color="primary"
                          style={{
                            fontSize: "0.6rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <FormattedMessage id="job_offer.be_the_first" />
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default JobOfferCardMobile;
