import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  LocalPhone,
  MailOutline,
  UnarchiveOutlined,
  CheckCircleOutline,
  WarningAmber,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import SkillView from "bundles/Recruiter/components/SkillView";
import ExperienceView from "bundles/common/components/ExperienceView";
import TrainingView from "bundles/common/components/TrainingView";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import PreferencesView from "../../Candidate/components/PreferencesView";
import { truncate } from "../../common/utils/utils";
import { getFullPortfolioURL } from "bundles/common/utils/utils";
import HTML from "bundles/common/components/HTML";
import sh from "bundles/common/utils/sh";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants";

const CandidateSummary = ({
  candidate,
  children,
  standalone = true,
  blur = true,
}) => {
  const i18nLocale = useSelector(state => state.i18nLocale);
  const currentRecruiter = useSelector(state => state.current_recruiter);
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    root: {
      maxHeight: "100%",
    },
    paper: {
      padding: standalone ? theme.spacing(2) : "32px 32px",
      borderRadius: isDesktop ? "8px" : "0px",
      borderTopLeftRadius: standalone ? (isDesktop ? "8px" : "0px") : "0px",
      borderBottomLeftRadius: standalone ? (isDesktop ? "8px" : "0px") : "0px",
    },
    avatar: {
      width: 150,
      height: 150,
      fontSize: 30,
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
  }));
  const classes = useStyles();
  const [blurredInformation, setBlurredInformation] = useState(blur);
  const [justUnblurredCandidates, setJustUnblurredCandidates] = useState([]);

  useEffect(() => {
    if (currentRecruiter?.id) {
      setBlurredInformation(
        blur &&
          !currentRecruiter?.candidate_interests?.some(interest => {
            return interest["candidate_id"] === candidate?.id;
          })
      );
    }
  }, [blur, candidate?.id, currentRecruiter?.candidate_interests?.length]);

  const availabilityColors = {
    looking: theme.palette.primary.main,
    listening: theme.palette.secondary.main,
    currently_employed: theme.palette.text.warning,
  };
  const authenticityToken = useSelector(state => state.authenticity_token);

  const hard_skills =
    candidate?.skills?.filter(skill => skill.category == "hard") ||
    candidate?.hard_skills ||
    [];
  const soft_skills =
    candidate?.skills?.filter(skill => skill.category == "soft") ||
    candidate?.soft_skills ||
    [];
  const languages =
    candidate?.skills?.filter(skill => skill.category == "lang") ||
    candidate?.language_skills ||
    [];

  const hasPreferences =
    candidate?.desired_job_title ||
    candidate?.desired_job_type ||
    candidate?.desired_sector ||
    candidate?.region ||
    candidate?.desired_locations?.length > 0;

  const revealCandidate = () => {
    sh.post("/recruiter/reveal_candidate", {
      authenticity_token: authenticityToken,
      candidate_id: candidate.id,
    }).then(() => {
      setJustUnblurredCandidates((prev) => [...prev, candidate.id]);
      replaceCurrentRecruiter(dispatch, authenticityToken, () => {});
    });
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        component={Paper}
        elevation={1}
        className={classes.paper}
      >
        <Grid container spacing={2}>
          {/* Header */}
          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <Grid
              container
              spacing={4}
              align={isDesktop ? "left" : "center"}
              alignItems="stretch"
            >
              <Grid item xs={12} md={4}>
                <ProfilePicture
                  picture={candidate?.profile_picture_url}
                  rounded
                  size={150}
                  initials={
                    candidate?.first_name?.[0] +
                    (candidate?.last_name ? candidate?.last_name[0] : "")
                  }
                  alt={`${candidate?.first_name} ${candidate?.last_name}`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                display="flex"
                flexDirection="column"
                justifyContent="space-evenly"
              >
                <Grid container>
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center", columnGap: "12px" }}>
                    <Typography variant="h4">
                      <b>{candidate.first_name} </b>{" "}
                      <b>{candidate.last_name}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1bold">
                      <b>{candidate.job_title}</b>
                    </Typography>
                  </Grid>
                  {candidate.availability && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "4px",
                      }}
                    >
                      <Typography variant="body3">
                        <FormattedMessage
                          id={`candidate.${candidate?.availability}`}
                        />
                      </Typography>
                      {candidate?.availability == "currently_employed" ? (
                        <WarningAmber
                          style={{
                            color: availabilityColors[candidate?.availability],
                            height: "10px",
                          }}
                        />
                      ) : (
                        <CheckCircleOutline
                          style={{
                            color: availabilityColors[candidate?.availability],
                            height: "10px",
                          }}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid container style={{ marginTop: "8px" }}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography
                          variant="body3"
                          component="div"
                          style={{ color: theme.palette.text.placeholder }}
                        >
                          <FormattedMessage id="candidate_summary.status" />
                        </Typography>
                        <Typography variant="body2bold" component="div">
                          <FormattedMessage
                            id={
                              candidate.candidate_status
                                ? `candidate.status_map.${candidate.candidate_status}`
                                : "undefined"
                            }
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="body3"
                          component="div"
                          style={{ color: theme.palette.text.placeholder }}
                        >
                          <FormattedMessage id="candidate_summary.region" />
                        </Typography>
                        <Typography variant="body2bold" component="div">
                          {candidate?.region ? (
                            truncate(
                              intl.formatMessage({
                                id: `regions.${candidate.region}`,
                              }),
                              10
                            )
                          ) : (
                            <FormattedMessage id="undefined" />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="body3"
                          component="div"
                          style={{ color: theme.palette.text.placeholder }}
                        >
                          <FormattedMessage id="experience.experience" />
                        </Typography>
                        <Typography variant="body2bold" component="div">
                          {candidate?.experience_year ? (
                            <>
                              <span>{candidate.experience_year} </span>
                              <FormattedMessage
                                id={`candidate.${
                                  candidate.experience_year == 1
                                    ? "year"
                                    : "years"
                                }`}
                              />
                            </>
                          ) : (
                            <FormattedMessage id="undefined" />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography
                          variant="body3"
                          component="div"
                          style={{ color: theme.palette.text.placeholder }}
                        >
                          <FormattedMessage
                            id="candidate_summary.views"
                          />
                        </Typography>
                        <Box style={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
                          <VisibilityIcon style={{ color: theme.palette.text.blue }} />
                          <Typography
                            variant="body1bold"
                            style={{ color: theme.palette.text.blue }}
                          >
                            {candidate?.candidate_interests_count
                              ? candidate.candidate_interests_count +
                              (justUnblurredCandidates.includes(candidate.id) ? 1 : 0)
                              : 0 + (justUnblurredCandidates.includes(candidate.id) ? 1 : 0)}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography
                          variant="body3"
                          component="div"
                          style={{ color: theme.palette.text.placeholder }}
                        >
                          <FormattedMessage
                            id="candidate_summary.last_online"
                          />
                        </Typography>
                        <Typography variant="body2bold" component="div">
                          {candidate.new_last_sign_in_at ? (
                            new Date(candidate.new_last_sign_in_at).toLocaleDateString(
                              i18nLocale || "en",
                              { year: "numeric", month: "long", day: "numeric" }
                            )
                          ) : (
                            <FormattedMessage id="undefined" />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Coordinates */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: "16px" }}>
                <Typography
                  variant="h5"
                  style={{ color: theme.palette.common.blue }}
                  align="left"
                >
                  <b>
                    <FormattedMessage id="candidate_search.contact" />
                  </b>
                </Typography>
              </Grid>
              {blurredInformation ? (
                <Box
                  onClick={revealCandidate}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "16px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <MailOutline />
                    <LocalPhone />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      filter: "blur(2.5px)",
                      marginLeft: "32px",
                      position: "absolute",
                    }}
                  >
                    <Box>
                      <FormattedMessage id="candidate_search.easteregg" />
                    </Box>
                    <Box>
                      <FormattedMessage id="candidate_search.easteregg_number" />
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      marginLeft: "8px",
                    }}
                  >
                    <Button
                      variant="rounded"
                      color="secondaryContained"
                      size="xsmall"
                    >
                      <FormattedMessage id="candidate_search.click" />
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>
                  <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    <Grid container spacing={1} justifyContent="flex-start">
                      <Grid item>
                        <MailOutline />
                      </Grid>
                      <Grid item>
                        <Link
                          href={`mailto:${candidate?.email}`}
                          color="secondary"
                        >
                          {candidate.email}
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                  {candidate?.phone && (
                    <Grid item xs={12} style={{ paddingTop: "0px" }}>
                      <Grid container spacing={1} justifyContent="flex-start">
                        <Grid item>
                          <LocalPhone />
                        </Grid>
                        <Grid item>
                          {candidate?.phone ? (
                            <Link
                              href={`tel:${candidate?.phone}`}
                              color="secondary"
                            >
                              {candidate.phone}
                            </Link>
                          ) : (
                            <FormattedMessage id="undefined" />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
          </Grid>

          {/* Presentation */}
          {candidate?.presentation && (
            <Grid item xs={12} style={{ paddingTop: "16px" }}>
              <Grid container spacing={2} align="left">
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.common.blue }}
                  >
                    <b>
                      <FormattedMessage
                        align="center"
                        id="candidate.presentation"
                      />
                    </b>
                  </Typography>
                </Grid>
                {candidate?.presentation && (
                  <Grid item xs={12}>
                    <HTML html={candidate.presentation} />
                  </Grid>
                )}
              </Grid>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            </Grid>
          )}

          {/* Preferences */}
          {hasPreferences && (
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.common.blue }}
                    align="left"
                  >
                    <b>
                      <FormattedMessage
                        align="center"
                        id="candidate.preferences"
                      />
                    </b>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <PreferencesView candidate={candidate} />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            </Grid>
          )}

          {/* Hard Skills */}
          {hard_skills.length > 0 && (
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.common.blue }}
                    align="left"
                  >
                    <b>
                      <FormattedMessage id="candidate.profile.skills.hard" />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    columnGap="8px"
                    rowGap="8px"
                    flexWrap="wrap"
                  >
                    {hard_skills.map((value, index) => (
                      <SkillView
                        key={`skill--${index}--value`}
                        value={value}
                        category="hard"
                        locale={i18nLocale}
                        level={false}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            </Grid>
          )}

          {/* Soft Skills */}
          {soft_skills.length > 0 && (
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.common.blue }}
                    align="left"
                  >
                    <b>
                      <FormattedMessage id="candidate.profile.skills.soft" />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    columnGap="8px"
                    rowGap="8px"
                    flexWrap="wrap"
                  >
                    {soft_skills.map((value, index) => (
                      <SkillView
                        key={`skill--${index}--value`}
                        value={value}
                        category="soft"
                        locale={i18nLocale}
                        level={false}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            </Grid>
          )}

          {/* Language Skills */}
          {languages.length > 0 && (
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.common.blue }}
                    align="left"
                  >
                    <b>
                      <FormattedMessage id="candidate.profile.skills.lang" />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    columnGap="8px"
                    rowGap="8px"
                    flexWrap="wrap"
                  >
                    {languages.map((value, index) => (
                      <SkillView
                        key={`skill--${index}--value`}
                        value={value}
                        category="lang"
                        locale={i18nLocale}
                        level={true}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            </Grid>
          )}

          {/* Documents */}
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{ color: theme.palette.common.blue }}
                  align="left"
                >
                  <b>
                    <FormattedMessage
                      align="center"
                      id="profile.candidates_docs"
                    />
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "16px" }}>
                <Button
                  disabled={!candidate?.resume_url}
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!candidate?.resume_url) return;
                    // If the candidate is still blurred, we call revealCandidate
                    // to increment interest count, then open the resume.
                    revealCandidate();
                    window.open(candidate.resume_url, "_blank", "noopener,noreferrer");
                  }}
                >
                  <UnarchiveOutlined />
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ marginLeft: "8px" }}
                  >
                    <b>
                      {candidate.resume_url ? (
                        <FormattedMessage id="application.my_resume" />
                      ) : (
                        <FormattedMessage id="application.no_resume" />
                      )}
                    </b>
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "8px" }}>
                <Button
                  disabled={!candidate?.portfolio_url}
                  href={getFullPortfolioURL(candidate.portfolio_url)}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  <UnarchiveOutlined />
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ marginLeft: "8px" }}
                  >
                    <b>
                      {candidate.portfolio_url ? (
                        <FormattedMessage id="application.my_portfolio" />
                      ) : (
                        <FormattedMessage id="candidate_summary.no_portfolio" />
                      )}
                    </b>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
          </Grid>

          {/* Experience */}
          {candidate.experiences.length > 0 && (
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.common.blue }}
                    align="left"
                  >
                    <b>
                      <FormattedMessage
                        align="center"
                        id="experience.experience"
                      />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {candidate.experiences.length > 0 ? (
                      candidate?.experiences?.map((experience, i) => {
                        return (
                          <Grid item xs={12} key={`experience-${i}`}>
                            <ExperienceView experience={experience} />
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="body2bold">
                          <FormattedMessage id="undefined" />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            </Grid>
          )}

          {/* Formation */}
          {candidate?.trainings.length > 0 && (
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.common.blue }}
                    align="left"
                  >
                    <b>
                      <FormattedMessage align="center" id="training.training" />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {candidate?.trainings.length > 0 ? (
                      candidate?.trainings?.map((training, i) => {
                        return (
                          <Grid item xs={12} key={`training-${i}`}>
                            <TrainingView training={training} />
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="body2bold">
                          <FormattedMessage id="undefined" />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CandidateSummary;
