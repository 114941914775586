import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Autocomplete from "react-google-autocomplete";
import PhoneInput from "react-phone-input-2";
import { useSnackbar } from "notistack";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Dialog,
  Link,
  useMediaQuery,
  Switch,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { countryMap, pictureRatios } from "bundles/common/utils/constants";
import sh from "bundles/common/utils/sh";
import PictureDropzone from "bundles/common/components/PictureDropzone";
import FileDropzone from "bundles/common/components/FileDropzone";
import { uploadPicture } from "bundles/common/utils/uploadProfilePicture";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  uploadResume,
  attachResumeToCandidate,
} from "bundles/common/utils/uploadResume";
import {
  uploadPortfolio,
  attachPortfolioToCandidate,
} from "bundles/common/utils/uploadPortfolio";
import { getFullPortfolioURL, normalizeRegion } from "bundles/common/utils/utils";
import ImageCropper from "bundles/common/components/ImageCropper";
import CandidatePreviewModal from "bundles/Candidate/components/Settings/CandidatePreviewModal";
import RichTextEditor from "bundles/common/components/RichTextEditor";

const Profile = ({ completionCallback }) => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const defaultPhoneValue = "+32 490 12 34 56";
  const initialFormValues = {
    first_name: currentCandidate.first_name || "",
    last_name: currentCandidate.last_name || "",
    phone: currentCandidate.phone || "",
    email: currentCandidate.email || "",
    job_title: currentCandidate.job_title || "",
    portfolio_url: currentCandidate.portfolio_url || "",
    experience_year: currentCandidate.experience_year || "",
    presentation: currentCandidate.presentation || "",
    location:
      currentCandidate.city ||
      currentCandidate.zipcode ||
      (currentCandidate.region
        ? intl.formatMessage({ id: `regions.${currentCandidate.region}` })
        : null),
  };

  const useStyles = makeStyles((theme) => ({
    link: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      cursor: "pointer !important",
    },
  }));

  const classes = useStyles();

  const [textArea, setTextArea] = useState({
    presentation: currentCandidate.presentation || "",
  });

  const [generalInfoErrors, setGeneralInfoErrors] = useState({});
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [values, setValues] = useState(initialFormValues);
  const [isCropped, setIsCropped] = useState(false);
  const [isCropModalOpened, setIsCropModalOpened] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    currentCandidate.profile_picture_url
  );
  const [profilePictureUrl, setProfilePictureUrl] = useState(
    currentCandidate.profile_picture_url
  );
  const [resume, setResume] = useState(currentCandidate.resume_url);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialFiles, setInitialFiles] = useState(resume ? [resume] : []);

  const initialUploadedPortfolioUrl = currentCandidate?.portfolio_file_url;
  const initialPortfolioURL = values.portfolio_url;

  const [portfolioUrl, setPortfolioUrl] = useState(currentCandidate?.portfolio_url);
  const [uploadedPortfolio, setUploadedPortfolio] = useState(null);
  const [isPortfolioUploaded, setIsPortfolioUploaded] = useState(!!initialUploadedPortfolioUrl);
  const [portfolioURLEntered, setPortfolioURLEntered] = useState(false);

  const [workPermit, setWorkPermit] = useState(currentCandidate.work_permit);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name ? "" : required;
    }

    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? "" : required;
    }

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }

    if ("phone" in fieldValues && fieldValues.phone == defaultPhoneValue) {
      setValues({ ...values, ["phone"]: null });
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const onPlacesSelect = (place) => {
    if (place.address_components) {
      let compPostalCode = "";
      let compLocality = "";
      let compAdministrativeAreaLevel2 = "";
      let compAdministrativeAreaLevel1 = "";
      let countryShortName = "";
      let regionValue = "";

      place.address_components.forEach((c) => {
        console.log("Component:", c);
        console.log("Types:", c.types);

        if (c.types.includes("postal_code")) {
          compPostalCode = c.long_name;
        }
        if (c.types.includes("locality")) {
          compLocality = c.long_name;
        }
        if (c.types.includes("administrative_area_level_2")) {
          compAdministrativeAreaLevel2 = c.long_name
            ?.toLowerCase()
            ?.replace(/ /g, "_");
        }
        if (c.types.includes("administrative_area_level_1")) {
          compAdministrativeAreaLevel1 = c.long_name
            ?.toLowerCase()
            ?.replace(/ /g, "_");
        }
        if (c.types.includes("country")) {
          countryShortName = c.short_name.toLowerCase();
        }
      });

      if (countryShortName === "fr") {
        regionValue = compAdministrativeAreaLevel1 || compAdministrativeAreaLevel2;
        regionValue = normalizeRegion(regionValue);
      } else if (countryShortName === "be") {
        if (compAdministrativeAreaLevel2 === "luxembourg") {
          regionValue = "be_luxembourg";
        } else {
          regionValue = compAdministrativeAreaLevel2 || compAdministrativeAreaLevel1;
        }
      } else {
        regionValue = compAdministrativeAreaLevel2 || compAdministrativeAreaLevel1;
      }

      let newValues = {
        ...values,
        location: place.formatted_address,
        zipcode: compPostalCode,
        city: compLocality,
        region: regionValue,
        country: countryShortName,
      };

      setValues(newValues);
    }
  };

  const handlePhoneInputClick = () => {
    if (values.phone === defaultPhoneValue) {
      setValues({ ...values, ["phone"]: "" });
    }
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  const handleRichEditorValue = (name, value) => {
    textArea[name] = value;
    setTextArea(textArea);
  };

  const handleChange = (name, value) => {
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (values.portfolio_url !== initialPortfolioURL && initialUploadedPortfolioUrl) {
      deleteUploadedPortfolio();
    }
    if (validate(values)) {
      sh.put("candidate", {
        authenticity_token: authenticityToken,
        candidate: { ...values, ...textArea, id: currentCandidate.id, work_permit: workPermit },
      })
        .then((res) => {
          replaceCurrentCandidate(dispatch, authenticityToken);
          enqueueSnackbar(
            intl.formatMessage(
              { id: "candidate.updated_profile" },
              { percentage: res?.data.profile_completion }
            ),
            {
              variant: "success",
            }
          );
          if (completionCallback) completionCallback();
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar("Oops... Something went wrong", { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleResumeDelete = () => {
    const params = {
      authenticity_token: authenticityToken,
      candidate: {
        id: currentCandidate.id,
      },
      attachment: {
        field_name: "resume",
      },
    };
    sh.put("/purge_attachment", params)
      .then(() => {
        setResume(null);
        setInitialFiles([]);
        replaceCurrentCandidate(dispatch, authenticityToken);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteUploadedPortfolio = () => {
    const params = {
      authenticity_token: authenticityToken,
      candidate: {
        id: currentCandidate.id,
      },
      attachment: {
        field_name: "portfolio_file",
      },
    };

    sh.put("/purge_attachment", params)
      .then(() => {
        return sh.put("candidate", {
          authenticity_token: authenticityToken,
          candidate: {
            id: currentCandidate.id,
            portfolio_url: ""
          },
        });
      })
      .then(() => {
        replaceCurrentCandidate(dispatch, authenticityToken);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleDeletePortfolio = () => {
    const updatedValues = { ...values, portfolio_url: "" };

    setValues(updatedValues);
    setUploadedPortfolio(null);
    setIsPortfolioUploaded(false);
    setPortfolioURLEntered(false);

    if (initialUploadedPortfolioUrl) {
      deleteUploadedPortfolio();
    } else {
      setPortfolioUrl("");
    }
  };

  const handlePreviewClick = () => {
    window.open(currentCandidate.resume_url, "_blank");
  };

  const handleDeleteProfilePicture = () => {
    const params = {
      authenticity_token: authenticityToken,
      candidate: {
        id: currentCandidate.id,
      },
      attachment: {
        field_name: "profile_picture",
      },
    };

    const updatedValues = { ...values, profile_picture: "" };

    sh.put("/purge_attachment", params)
      .then(() => {
        setProfilePicture(null);
        setProfilePictureUrl("");
        setValues(updatedValues);
        replaceCurrentCandidate(dispatch, authenticityToken);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  useEffect(() => {
    if (resume && resume.length && typeof resume != "string") {
      uploadResume(
        resume[0],
        `resume-candidate-${Math.floor(Math.random() * 100001)}_${
          currentCandidate.id
        }`
      ).then((res) => {
        attachResumeToCandidate({
          id: currentCandidate.id,
          authenticity_token: authenticityToken,
          blob_signed_id: res.blob_signed_id,
        })
          .then((res) => {
            let newValues = {
              ...values,
              resume: res.resume_url,
            };
            setValues(newValues);
            replaceCurrentCandidate(dispatch, authenticityToken);
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_success" }),
              {
                variant: "success",
              }
            );
          })
          .catch(() => {
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_failed" }),
              {
                variant: "error",
              }
            );
          });
      });
    }
  }, [resume]);

  useEffect(() => {
    if (uploadedPortfolio && uploadedPortfolio.length && typeof uploadedPortfolio != "string") {
      setIsLoading(true);
      uploadPortfolio(
        uploadedPortfolio[0],
        `portfolio-candidate-${Math.floor(Math.random() * 100001)}_${currentCandidate.id}`
      )
        .then((res) => {
          attachPortfolioToCandidate({
            id: currentCandidate.id,
            authenticity_token: authenticityToken,
            blob_signed_id: res.blob_signed_id,
          })
            .then((res) => {
              let newValues = {
                ...values,
                portfolio_url: res.portfolio_file_url,
              };
              setValues(newValues);
              replaceCurrentCandidate(dispatch, authenticityToken);
              enqueueSnackbar(
                intl.formatMessage({ id: "settings.save_success" }),
                {
                  variant: "success",
                }
              );
            })
            .catch(() => {
              enqueueSnackbar(
                intl.formatMessage({ id: "settings.save_failed" }),
                {
                  variant: "error",
                }
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        });
    }
  }, [uploadedPortfolio]);

  useEffect(() => {
    setPortfolioUrl(currentCandidate.portfolio_url);
  }, [currentCandidate]);


  useEffect(() => {
    if (profilePicture && typeof profilePicture != "string") {
      uploadPicture(
        profilePicture,
        currentCandidate.id,
        "candidate",
        "profile_picture",
        authenticityToken
      )
        .then((res) => {
          let newValues = {
            ...values,
            profile_picture: res.profile_picture_url,
          };
          setValues(newValues);
          setProfilePictureUrl(res.profile_picture_url);

          if (isCropped) {
            setIsCropped(false);
            setIsCropModalOpened(false);
            replaceCurrentCandidate(dispatch, authenticityToken);
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_success" }),
              {
                variant: "success",
              }
            );
          } else {
            setIsCropModalOpened(true);
          }
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
            variant: "error",
          });
        });
    }
  }, [profilePicture]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={9}
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">
              <FormattedMessage id="settings.general_info" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="rounded"
              color="secondaryOutlined"
              onClick={() => setIsPreviewModalOpen(true)}
              style={{ width: isDesktop ? "180px" : "100%" }}
            >
              <FormattedMessage id="settings.show" />
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              columnGap: isDesktop ? "32px" : "0px",
              rowGap: isDesktop ? "0px" : "16px",
              marginBottom: "16px",
              flexDirection: isDesktop ? "row" : "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{ marginBottom: "16px", alignSelf: "flex-start" }}
              >
                <FormattedMessage id="settings.profile_picture" />
              </Typography>
              <Box style={{ position: "relative", width: "200px", height: "200px" }}>
                <PictureDropzone
                  handleFileInput={(file) => {
                    setProfilePicture(file);
                    setValues({ ...values, profile_picture: "" });
                  }}
                  filesLimit={1}
                  maxFileSize={1024000}
                  pictureUrl={
                    typeof values.profile_picture == "string" &&
                      values.profile_picture != ""
                      ? values.profile_picture
                      : currentCandidate.profile_picture_url
                  }
                  width="200px"
                  height="200px"
                  type="candidate"
                />
                {profilePictureUrl && (
                  <IconButton
                    onClick={handleDeleteProfilePicture}
                    style={{
                      position: "absolute",
                      top: "-2px",
                      right: "-2px",
                      padding: "4px",
                      margin: "4px",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    <CloseIcon style={{ fontSize: "16px" }} />
                  </IconButton>
                )}
              </Box>
              <Typography variant="body3">
                <FormattedMessage id="settings.recommended_size" /> 250x250
              </Typography>
            </Box>
            <Box style={{ flexGrow: 1 }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" style={{ marginBottom: "16px" }}>
                  <FormattedMessage id="settings.resume" />
                </Typography>
                {resume && resume.length > 0 && (
                  <Link onClick={handlePreviewClick} className={classes.link}>
                    <Typography variant="body1">
                      <FormattedMessage id="settings.resume_preview" />
                    </Typography>
                  </Link>
                )}
              </Box>
              <FileDropzone
                handleFileInput={(file) => {
                  if (!resume?.includes(file[0]?.name)) {
                    setResume(file);
                    setInitialFiles(file);
                    setErrors({ ...errors, resume: "" });
                  }
                }}
                filesLimit={1}
                dropzoneTextId="devise.signup.drag_and_drop_zone"
                height="200px"
                initialFiles={initialFiles}
                showAddedFileMessage={false}
                handleFileDelete={handleResumeDelete}
                fileName={`${values.first_name} ${values.last_name} - CV.pdf`}
                type="candidate"
              />
              <Typography variant="body3" style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <FormattedMessage id="settings.cv_warning" />
              </Typography>
              <Typography
                align="center"
                variant="body1"
                style={{ color: theme.palette.error.main }}
              >
                {errors?.resume}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ marginBottom: "16px" }}>
              <FormattedMessage id="settings.personal_data" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label={<FormattedMessage id="devise.signup.first_name" />}
              name="first_name"
              value={values.first_name}
              variant="outlined"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              {...(errors["first_name"] && {
                error: true,
                helperText: errors["first_name"],
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              value={values.last_name}
              type="text"
              name="last_name"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              variant="outlined"
              label={<FormattedMessage id="devise.signup.last_name" />}
              {...(errors["last_name"] && {
                error: true,
                helperText: errors["last_name"],
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              value={values.email}
              type="text"
              name="email"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              variant="outlined"
              label={<FormattedMessage id="devise.signup.email" />}
              {...(errors["email"] && {
                error: true,
                helperText: errors["email"],
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneInput
              country={"be"}
              onlyCountries={countryMap.values}
              inputStyle={{
                height: "56px",
                minWidth: "100%",
                color:
                  values.phone == defaultPhoneValue
                    ? theme.palette.text.secondary
                    : theme.palette.text.primary,
              }}
              value={values.phone}
              color="primary"
              placeholder="+32 490 12 34 56"
              inputProps={{
                name: "phone",
                autoFocus: false,
                value: values.phone,
                onBlur: handleInputValue,
                onChange: handleInputValue,
                onClick: handlePhoneInputClick,
              }}
              {...(errors["phone"] && {
                error: true,
                helperText: errors["phone"],
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={values.job_title}
              type="text"
              name="job_title"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              variant="outlined"
              label={<FormattedMessage id="candidate.job_title" />}
              {...(errors["job_title"] && {
                error: true,
                helperText: errors["job_title"],
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={values.experience_year}
              type="number"
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              name="experience_year"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              variant="outlined"
              label={<FormattedMessage id="candidate.experience" />}
              {...(errors["experience_year"] && {
                error: true,
                helperText: errors["experience_year"],
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              color="primary"
              name="location"
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "devise.signup.search_city_or_zipcode",
              })}
              InputProps={{
                inputComponent: ({ ...props }) => (
                  <Autocomplete
                    /* eslint-disable no-undef */
                    apiKey={process.env.GOOGLE_AUTOCOMPLETE_API_KEY}
                    multiple
                    language="en"
                    {...props}
                    onPlaceSelected={onPlacesSelect}
                    options={{
                      types: ["(regions)"],
                      componentRestrictions: {
                        country: ["be", "lu", "fr"],
                      },
                    }}
                    defaultValue={values.location}
                  />
                ),
              }}
              {...(values["locations"] && {
                error: true,
                helperText: values["locations"],
              })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box style={{ display: "flex", width: "100%" }}>
              <Box
                style={{
                  flexGrow: 1,
                  borderLeft: "1px solid #bdbdbd",
                  borderTop: "1px solid #bdbdbd",
                  borderBottom: "1px solid #bdbdbd",
                  borderTopLeftRadius: "4px",
                  borderBottomLeftRadius: "4px",
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">
                  <FormattedMessage id="work_permit" />
                </Typography>
              </Box>
              <Box
                style={{
                  border: "1px solid #bdbdbd",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  padding: "8px 16px",
                }}
              >
                <Switch
                  checked={workPermit}
                  color="primary"
                  onChange={() => setWorkPermit(!workPermit)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ marginTop: "16px", marginBottom: "16px" }}>
              <FormattedMessage id="candidate.presentation" />
            </Typography>
            <RichTextEditor
              onChange={(v) => handleRichEditorValue("presentation", v)}
              defaultValue={
                currentCandidate.presentation || textArea.presentation
              }
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "16px" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" style={{ marginBottom: "8px" }}>
                <FormattedMessage id="candidate.portfolio" />
              </Typography>
            </Box>
            {isLoading && <CircularProgress size={24} color="primary" />}
            <Box style={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%", marginTop: "16px" }}>
              {/* Input for Portfolio URL */}
              {!portfolioUrl && !isPortfolioUploaded && (
                <TextField
                  fullWidth
                  value={values.portfolio_url}
                  type="text"
                  name="portfolio_url"
                  onBlur={handleInputValue}
                  onChange={(e) => {
                    handleInputValue(e);
                    const isValueEntered = !!e.target.value;
                    setPortfolioURLEntered(isValueEntered);
                  }}
                  variant="outlined"
                  placeholder="https://www.example.com"
                  label={<FormattedMessage id="settings.enter_portfolio" />}
                  {...(errors["portfolio_url"] && {
                    error: true,
                    helperText: errors["portfolio_url"],
                  })}
                />
              )}


              {/* File Dropzone */}
              {!portfolioURLEntered && !isPortfolioUploaded && !portfolioUrl && (
                <>
                  <Box display="flex" justifyContent="center" alignItems="center" height="20px">
                    <Typography variant="body1">
                      <FormattedMessage id="settings.or" />
                    </Typography>
                  </Box>
                  <FileDropzone
                    handleFileInput={(file) => {
                      setUploadedPortfolio(file);
                      setIsPortfolioUploaded(true);
                      setPortfolioURLEntered(false);
                    }}
                    filesLimit={1}
                    height="100px"
                    showAddedFileMessage={false}
                    type="candidate"
                    label={<FormattedMessage id="settings.upload_portfolio" />}
                  />
                </>
              )}

              {/* Generic Portfolio Display with Delete and Preview Options */}
              {portfolioUrl && (
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" marginRight={6}>
                    <Typography variant="body2" className={classes.fileName}>
                      {`Portfolio - ${values.first_name} ${values.last_name}`}
                    </Typography>
                    <IconButton
                      className={classes.closeButton}
                      aria-label="Close"
                      onClick={handleDeletePortfolio}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  <Box>
                    <Link
                      href={getFullPortfolioURL(portfolioUrl)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.link}
                    >
                      <Typography variant="body1">
                        <FormattedMessage id="settings.portfolio_preview" />
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              )}
            </Box>
            <Typography align="center" variant="body1" style={{ color: theme.palette.error.main }}>
              {errors?.portfolio}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              disabled={loading}
              type="submit"
              variant="rounded"
              color="primaryContained"
              style={{ width: "200px", marginTop: "16px", marginBottom: "8px" }}
            >
              <FormattedMessage id="save" />
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={isCropModalOpened}
        onClose={() => setIsCropModalOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth="xl"
        maxWidth
      >
        <ImageCropper
          ratio={pictureRatios.profilePicture}
          src={profilePictureUrl}
          filename={
            profilePictureUrl?.split("filename=")[1] ||
            `profile_picture_${currentCandidate.lastname}`
          }
          closeModal={() => setIsCropModalOpened(false)}
          handleNewImg={(img, callback) => {
            setIsCropped(true);
            setProfilePicture(img[0]);
            setGeneralInfoErrors({
              ...generalInfoErrors,
              profile_picture: "",
            });
            if (callback) callback();
          }}
        />
      </Dialog>
      <CandidatePreviewModal
        isOpen={isPreviewModalOpen}
        setIsOpen={setIsPreviewModalOpen}
        candidate={currentCandidate}
      />
    </>
  );
};

export default Profile;
