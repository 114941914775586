import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Paper,
  LinearProgress,
  Button,
  useTheme,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import { FormattedMessage } from "react-intl";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { truncate } from "bundles/common/utils/utils";

const attributeActions = {
  first_name: {
    link: "/candidate/settings",
  },
  last_name: {
    link: "/candidate/settings",
  },
  email: {
    link: "/candidate/settings",
  },
  phone: {
    link: "/candidate/settings",
  },
  job_title: {
    link: "/candidate/settings",
  },
  experience_year: {
    link: "/candidate/settings",
  },
  region: {
    link: "/candidate/settings",
  },
  portfolio_url: {
    link: "/candidate/settings",
  },
  presentation: {
    link: "/candidate/settings",
  },
  profile_picture_url: {
    link: "/candidate/settings",
  },
  resume_url: {
    link: "/candidate/settings",
  },
  availability: {
    link: "/candidate/settings?selectedSection=your_search",
  },
  desired_sector: {
    link: "/candidate/settings?selectedSection=your_search",
  },
  candidate_status: {
    link: "/candidate/settings?selectedSection=your_search",
  },
  resource_skills_count: {
    link: "/candidate/settings?selectedSection=skills",
  },
  experiences_count: {
    link: "/candidate/settings?selectedSection=experience",
  },
  trainings_count: {
    link: "/candidate/settings?selectedSection=training",
  },
};

const ProfileCompletion = () => {
  const theme = useTheme();

  const i18nLocale = useSelector(state => state.i18nLocale);
  const currentCandidate = useSelector(state => state.current_candidate);

  const useStyles = makeStyles(theme => ({
    container: {
      height: "100%",
    },
    profileHeader: {
      display: "flex",
      alignItems: "center",
    },
    profileDetails: {
      marginLeft: theme.spacing(2),
      flexGrow: 1,
    },
    profileDetailsLine: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    jobTitleLine: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      overflow: "hidden",
    },
    jobTitle: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingBottom: "6px",
      fontSize: "14px",
    },
    progressContainer: {
      marginTop: theme.spacing(2),
    },
    progressBar: {
      height: "10px",
      borderRadius: "5px",
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
    },
    capitalizeText: {
      textTransform: "capitalize",
    },
    suggestionButton: {
      marginBottom: theme.spacing(1),
      justifyContent: "space-between",
    },
    buttonContent: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      textTransform: "none",
    },
    leftContent: {
      display: "flex",
      alignItems: "center",
    },
    percentageText: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.green,
      marginLeft: "5px",
    },
  }));

  const classes = useStyles();

  const availabilityColors = {
    looking: theme.palette.text.green,
    listening: theme.palette.text.green,
    currently_employed: theme.palette.text.blue,
    unavailable: theme.palette.text.grey,
  };

  const availabilityStatus = currentCandidate?.availability || "unavailable";
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [missingAttributes, setMissingAttributes] = useState([]);

  useEffect(() => {
    if (!currentCandidate) return;

    setProfileCompletion(currentCandidate.profile_completion || 0);
    setMissingAttributes(currentCandidate.missing_attributes || []);
  }, [currentCandidate]);

  return (
    <Box>
      <Paper
        elevation={3}
        style={{ padding: theme.spacing(2), borderRadius: "15px" }}
      >
        <Box className={classes.profileHeader}>
          <ProfilePicture
            rounded
            size="100"
            picture={currentCandidate?.profile_picture_url}
            initials={
              currentCandidate?.first_name?.[0] +
              (currentCandidate?.last_name
                ? currentCandidate?.last_name[0]
                : "")
            }
            alt={`${currentCandidate?.first_name} ${currentCandidate?.last_name}`}
          />
          <Box className={classes.profileDetails}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h5" style={{ fontSize: "18px" }}>
                {currentCandidate?.first_name} {currentCandidate?.last_name}
              </Typography>
              <IconButton
                color="secondary"
                size="small"
                style={{ marginLeft: "5px" }}
                onClick={() =>
                  (window.location.href = `/${i18nLocale}/candidate/settings`)
                }
              >
                <EditOutlinedIcon />
              </IconButton>
            </Box>
            <Box className={classes.jobTitleLine}>
              <Typography
                variant="body2"
                className={`${classes.capitalizeText} ${classes.jobTitle}`}
              >
                {currentCandidate?.job_title ? (
                  truncate(currentCandidate.job_title, 25)
                ) : (
                  <FormattedMessage id="candidate.job_title_missing" />
                )}
              </Typography>
              <Box
                color="secondary"
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={() =>
                  (window.location.href = `/${i18nLocale}/candidate/settings`)
                }
              >
                <EditOutlinedIcon style={{ fontSize: "18px" }} />
              </Box>
            </Box>
            <Box className={classes.profileDetailsLine}>
              <CircleIcon
                sx={{
                  fontSize: 10,
                  color: availabilityColors[availabilityStatus] || "grey",
                  marginRight: "8px",
                }}
              />
              <Typography variant="body2">
                <FormattedMessage id={`candidate.${availabilityStatus}`} />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.progressContainer}>
          <Typography variant="body2">
            <FormattedMessage
              id="candidate.profile_completion"
              values={{ percentage: profileCompletion }}
            />
          </Typography>
          <LinearProgress
            variant="determinate"
            value={profileCompletion}
            className={classes.progressBar}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          {missingAttributes.length > 0 ? (
            missingAttributes.slice(0, 2).map(attribute => {
              const { attribute_name, weight } = attribute;
              const action = attributeActions[attribute_name];
              return action ? (
                <Button
                  key={attribute_name}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.suggestionButton}
                  onClick={() =>
                    (window.location.href = `/${i18nLocale}${action.link}`)
                  }
                >
                  <Box className={classes.buttonContent}>
                    <Box className={classes.leftContent}>
                      <Typography variant="body2">
                        <FormattedMessage
                          id={`candidate.attribute_actions.${attribute_name}`}
                        />
                      </Typography>
                      <Typography
                        variant="h7"
                        className={classes.percentageText}
                      >
                        {`(+${weight}%)`}
                      </Typography>
                    </Box>
                    <ArrowCircleRightOutlinedIcon />
                  </Box>
                </Button>
              ) : null;
            })
          ) : (
            <Typography variant="body2" align="center">
              <FormattedMessage id="candidate.profile_complete" />
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ProfileCompletion;
