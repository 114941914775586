import React from "react";
import { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import sh from "bundles/common/utils/sh";
import { countryMap } from "bundles/common/utils/constants";
import PhoneInput from "react-phone-input-2";
import { Link } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { trackDownloadPricing } from "../../common/utils/marketing";

const PricingModal = ({ closeModal }) => {
  const steps = [
    { index: 0, key: 1, finale: false },
    { index: 1, key: 2, finale: false },
    { index: 2, key: 3, finale: true },
  ];

  const theme = useTheme();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const stepTransition = isDesktop ? 600 : 240;
  const useStyles = makeStyles((theme) => {
    return {
      modalBox: {
        width: "100vw",
        height: "100vh",
        overflow: "auto",
        display: "flex",
        top: 0,
        bottom: 0,
        zIndex: 999,
        padding: "32px",
        backgroundColor: "white",
      },
      modalContainer: {
        marginLeft: isDesktop ? "auto !important" : "0px !important",
        marginRight: isDesktop ? "auto !important" : "0px !important",
        width: isDesktop ? "50% !important" : "100% !important",
      },
      stepper: {
        width: isDesktop ? "80%" : "100%",
        marginLeft: "auto",
        marginRight: "auto",
        "& .MuiStepLabel-iconContainer": {
          padding: "0px",
        },
        "& .MuiStepIcon-root": {
          height: "40px",
          width: "40px",
          color: `${theme.palette.secondary.main} !important`,
        },
        "& .MuiStepIcon-active": {
          height: "40px",
          width: "40px",
          color: `${theme.palette.secondary.main} !important`,
        },
        "& .MuiStepIcon-completed": {
          height: "40px",
          width: "40px",
          color: `${theme.palette.secondary.main} !important`,
        },
        "& .Mui-disabled .MuiStepIcon-root": {
          height: "40px",
          width: "40px",
          color: `${theme.palette.text.disabled} !important`,
        },
      },
      modalForm: {
        marginTop: "auto",
        marginBottom: "auto",
        overflow: "hidden",
      },
      modalInput: {
        "& .MuiInputLabel-root": {
          color: theme.palette.text.placeholder,
        },
      },
      closeBox: {
        position: "absolute",
        top: "16px",
        right: "16px",
      },
      stepBox: {
        flex: "0 0 auto",
        width: "100%",
        marginTop: isDesktop ? "0px" : "32px",
      },
      rpCard: {
        height: "48px",
        display: "flex",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.text.primary,
        width: isDesktop ? "120px" : "80px",

        "&:hover": {
          border: "1px solid rgba(0, 0, 0, 0.5)",
        },
      },

      rpCardSelected: {
        border: "1px solid rgba(0, 0, 0, 0.5)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: theme.palette.error.contrastText,
        fontFamily: "Work Sans Bold",
        fontWeight: 600,
      },
    };
  });
  const classes = useStyles();

  const defaultPhoneValue = "+32 490 12 34 56";
  const initialFormValues = {
    last_name: "",
    first_name: "",
    email: "",
    phone: defaultPhoneValue,
    job_title: "",
    company_name: "",
    recruitment_plan: "",
  };

  const stepValues = {
    1: ["first_name", "last_name"],
    2: ["email", "phone"],
    3: ["company_name", "job_title", "recruitment_plan"],
  };

  const [values, setValues] = useState(initialFormValues);
  const [currentStep, setCurrentStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const railsEnv = useSelector((state) => state.railsEnv);

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handlePhoneInputClick = () => {
    if (values.phone === defaultPhoneValue)
      setValues({ ...values, ["phone"]: "" });
  };

  const onPlanSelected = (plan) => {
    setSelectedPlan(plan);
    setValues({ ...values, ["recruitment_plan"]: plan });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let step = steps.find((step) => step.index == currentStep);

    if (step.finale) {
      sh.post(`/${i18nLocale}/pricing-lead`, {
        data: values,
      })
        .then(() => {
          let locale = i18nLocale || "en";
          let url = `https://squarehub-production.s3.${process.env.AWS_REGION}.amazonaws.com/docs/squarehub_2025_${locale}.pdf`;
          let link = document.createElement("a");
          link.href = url;
          link.download = url;
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent("click"));
          enqueueSnackbar(
            intl.formatMessage({ id: "pricing_modal.success_snack" }),
            { variant: "success" }
          );
          if (railsEnv === "production") trackDownloadPricing();
          setTimeout(() => {
            closeModal();
          }, 3000);
        })
        .catch(() => {
          enqueueSnackbar(
            intl.formatMessage({ id: "pricing_modal.failed_snack" }),
            { variant: "error" }
          );
        });
    } else {
      if (currentStep == 1 && values.phone == defaultPhoneValue) {
        setPhoneError("default_phone_error");
      } else {
        setPreviousStep(step.index);
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const goBack = () => {
    let step = currentStep;
    if (step > 0) {
      setPreviousStep(step);
      setCurrentStep(step - 1);
    }
  };

  const close = () => {
    closeModal();
  };

  function hasStepValue(step) {
    let currentValues = Object.keys(values).filter(
      (v) => values[v] != "" && values[v] != null
    );

    return currentValues.some((element) => {
      return stepValues[step].includes(element);
    });
  }

  function hasCompletedStep(step) {
    let currentValues = Object.keys(values).filter(
      (v) => values[v] != "" && values[v] != null
    );

    return (
      currentValues.length > 0 &&
      stepValues[step].every((element) => {
        if (
          step == 2 &&
          (values.phone === defaultPhoneValue ||
            !values.phone
              .replaceAll(" ", "")
              .match("^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"))
        )
          return false;

        return currentValues.includes(element);
      })
    );
  }

  function StepOne(x) {
    return (
      <motion.div
        initial={{ x: x }}
        transition={{ duration: 0.5 }}
        animate={{ x: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={isDesktop ? 6 : 12}>
            <TextField
              className={classes.modalInput}
              required
              autoFocus
              fullWidth
              variant="outlined"
              label={
                <FormattedMessage id="pricing_modal.step_one.first_name" />
              }
              name="first_name"
              value={values.first_name}
              color="secondary"
              onBlur={handleInputValue}
              onChange={handleInputValue}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <TextField
              className={classes.modalInput}
              required
              fullWidth
              variant="outlined"
              label={<FormattedMessage id="pricing_modal.step_one.last_name" />}
              name="last_name"
              value={values.last_name}
              color="secondary"
              onBlur={handleInputValue}
              onChange={handleInputValue}
            />
          </Grid>
          <Grid
            item
            xs={isDesktop ? 6 : 0}
            style={{ padding: isDesktop ? "8px" : "0px" }}
          ></Grid>
          <Grid
            item
            xs={isDesktop ? 6 : 12}
            style={{ justifyContent: "end", display: "flex" }}
          >
            <Button
              type="submit"
              variant="rounded"
              color="secondaryOutlined"
              disabled={!hasCompletedStep(1)}
              style={{
                minWidth: isDesktop ? "50%" : "100%",
              }}
            >
              <FormattedMessage id="pricing_modal.next_button" />
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            <Typography
              variant="body2"
              style={{ color: theme.palette.text.secondary }}
            >
              <FormattedMessage id="pricing_modal.required" />
            </Typography>
          </Grid>
        </Grid>
      </motion.div>
    );
  }
  function StepTwo(x) {
    return (
      <motion.div
        initial={{ x: x }}
        transition={{ duration: 0.5 }}
        animate={{ x: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={isDesktop ? 6 : 12}>
            <TextField
              className={classes.modalInput}
              required
              fullWidth
              variant="outlined"
              color="secondary"
              type="email"
              name="email"
              value={values.email}
              label={<FormattedMessage id="pricing_modal.step_two.email" />}
              onBlur={handleInputValue}
              onChange={handleInputValue}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <PhoneInput
              country={"be"}
              onlyCountries={countryMap.values}
              inputStyle={{
                height: "56px",
                minWidth: "100%",
                color:
                  values.phone == defaultPhoneValue
                    ? theme.palette.text.secondary
                    : theme.palette.text.primary,
              }}
              value={values.phone}
              color="primary"
              placeholder="+32 490 12 34 56"
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: false,
                onBlur: handleInputValue,
                onChange: handleInputValue,
                onClick: handlePhoneInputClick,
              }}
            />
            {phoneError && (
              <Typography>Please enter your phone number</Typography>
            )}
          </Grid>
          {isDesktop && (
            <Grid item xs={6}>
              <Button
                type="button"
                variant="rounded"
                color="secondaryOutlined"
                onClick={goBack}
                style={{
                  minWidth: isDesktop ? "50%" : "100%",
                }}
              >
                <FormattedMessage id="pricing_modal.previous_button" />
              </Button>
            </Grid>
          )}
          <Grid
            item
            xs={isDesktop ? 6 : 12}
            style={{ justifyContent: "end", display: "flex" }}
          >
            <Button
              type="submit"
              variant="rounded"
              color="secondaryContained"
              disabled={!hasCompletedStep(2)}
              style={{
                minWidth: isDesktop ? "50%" : "100%",
              }}
            >
              <FormattedMessage id="pricing_modal.next_button" />
            </Button>
          </Grid>
          {!isDesktop && (
            <Grid item xs={12}>
              <Button
                type="button"
                variant="rounded"
                color="secondaryOutlined"
                onClick={goBack}
                style={{
                  minWidth: isDesktop ? "50%" : "100%",
                }}
              >
                <FormattedMessage id="pricing_modal.previous_button" />
              </Button>
            </Grid>
          )}
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography
              variant="body2"
              style={{ color: theme.palette.text.secondary }}
            >
              <FormattedMessage id="pricing_modal.required" />
            </Typography>
          </Grid>
        </Grid>
      </motion.div>
    );
  }
  function StepThree(x) {
    return (
      <motion.div
        initial={{ x: x }}
        transition={{ duration: 0.5 }}
        animate={{ x: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={isDesktop ? 6 : 12}>
            <TextField
              className={classes.modalInput}
              required
              fullWidth
              variant="outlined"
              label={
                <FormattedMessage id="pricing_modal.step_three.company_name" />
              }
              name="company_name"
              value={values.company_name}
              color="secondary"
              onBlur={handleInputValue}
              onChange={handleInputValue}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <TextField
              className={classes.modalInput}
              required
              fullWidth
              variant="outlined"
              label={
                <FormattedMessage id="pricing_modal.step_three.job_title" />
              }
              name="job_title"
              value={values.job_title}
              color="secondary"
              onBlur={handleInputValue}
              onChange={handleInputValue}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: isDesktop ? "32px" : "8px",
              marginBottom: isDesktop ? "16px" : "8px",
              textAlign: "center",
            }}
          >
            <Typography>
              <FormattedMessage id="pricing_modal.step_three.recruitment_plan" />
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginLeft: isDesktop ? "28%" : "0px",
              marginRight: isDesktop ? "28%" : "0px",
              marginBottom: isDesktop ? "32px" : "8px",
            }}
          >
            <Grid container spacing={4}>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  className={
                    selectedPlan == "1-4"
                      ? `${classes.rpCard} ${classes.rpCardSelected}`
                      : classes.rpCard
                  }
                  onClick={() => onPlanSelected("1-4")}
                >
                  1 - 4
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  className={
                    selectedPlan == "5-10"
                      ? `${classes.rpCard} ${classes.rpCardSelected}`
                      : classes.rpCard
                  }
                  onClick={() => onPlanSelected("5-10")}
                >
                  5 - 10
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  className={
                    selectedPlan == "11+"
                      ? `${classes.rpCard} ${classes.rpCardSelected}`
                      : classes.rpCard
                  }
                  onClick={() => onPlanSelected("11+")}
                >
                  11+
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {isDesktop && (
            <Grid item xs={6}>
              <Button
                type="button"
                variant="rounded"
                color="secondaryOutlined"
                onClick={goBack}
                style={{
                  minWidth: isDesktop ? "50%" : "100%",
                }}
              >
                <FormattedMessage id="pricing_modal.previous_button" />
              </Button>
            </Grid>
          )}
          <Grid
            item
            xs={isDesktop ? 6 : 12}
            style={{ justifyContent: "end", display: "flex" }}
          >
            <Button
              type="submit"
              variant="rounded"
              color="secondaryContained"
              disabled={!hasCompletedStep(3)}
              style={{
                minWidth: isDesktop ? "50%" : "100%",
              }}
            >
              <b>
                <FormattedMessage id="pricing_modal.download_button" />
              </b>
            </Button>
          </Grid>
          {!isDesktop && (
            <Grid item xs={12}>
              <Button
                type="button"
                variant="rounded"
                color="secondaryOutlined"
                onClick={goBack}
                style={{
                  minWidth: isDesktop ? "50%" : "100%",
                }}
              >
                <FormattedMessage id="pricing_modal.previous_button" />
              </Button>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            style={{
              textAlign: isDesktop ? "left" : "center",
              marginTop: isDesktop ? "16px" : "4px",
            }}
          >
            <Typography
              variant="body2"
              style={{ color: theme.palette.text.secondary }}
            >
              {intl.formatMessage(
                { id: "pricing_modal.step_three.disclaimer" },
                {
                  a: (str) => (
                    <Link
                      href="privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: theme.palette.secondary.main }}
                    >
                      <b>{str}</b>
                    </Link>
                  ),
                }
              )}
            </Typography>
          </Grid>
        </Grid>
      </motion.div>
    );
  }

  return (
    <Box className={classes.modalBox}>
      <Box className={classes.closeBox}>
        <IconButton edge="end" aria-label="menu" onClick={close}>
          <CloseIcon />
        </IconButton>
      </Box>
      <form onSubmit={handleSubmit} className={classes.modalForm}>
        <Grid
          container
          spacing={isDesktop ? 4 : 2}
          className={classes.modalContainer}
        >
          <Grid item xs={12}>
            <Stepper
              activeStep={currentStep}
              className={classes.stepper}
              color="secondary"
            >
              {steps.map((step) => {
                return (
                  <Step key={step.key}>
                    <StepLabel />
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={isDesktop ? 8 : 2}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center">
                  <FormattedMessage id="pricing_modal.title" />
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                {currentStep === 0 && (
                  <Box className={classes.stepBox}>
                    {StepOne(hasStepValue(1) ? -stepTransition : 0)}
                  </Box>
                )}
                {currentStep === 1 && (
                  <Box className={classes.stepBox}>
                    {StepTwo(
                      !hasStepValue(2)
                        ? stepTransition
                        : previousStep < currentStep
                          ? stepTransition
                          : -stepTransition
                    )}
                  </Box>
                )}
                {currentStep === 2 && (
                  <Box className={classes.stepBox}>
                    {StepThree(stepTransition)}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default PricingModal;
